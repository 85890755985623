import React from 'react';
// import {useState} from 'react';

// Bootstrap
import { Container, Row, Col, Tab, Nav, CardDeck } from 'react-bootstrap';

// components
import FilterNavigation from '../../shop/components/FilterNavigation';
import ProductTile from '../../shop/components/ProductTile';



function ProfilePage() {

  // const [key, setKey] = useState('more');

  const testpic = "https://proxy.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.lz.de%2F_em_daten%2F_cache%2Fimage%2F1x9q7rL2z8GLrf01PC37sbTB2UL3ASo1SCE0DPxxV1Ewk60O7HVkEAE2a-ktKfvOSQeQZ7niq5lWNHOLJbJhZYIg%2F161103-1237-orighight130t3428128680608319624img.jpg&f=1&nofb=1";
  const testpic2 = "https://proxy.duckduckgo.com/iu/?u=http://l7.alamy.com/zooms/9441eef4b8604dfdb43ad1e4f87fa0a9/historischer-bauernhof-freilichtmuseum-mhlenhof-nordrhein-westfalen-j106cr.jpg&f=1&nofb=1";

  function renderProducts() {
    const amount = 10;
    var productTiles = [];

    for(var i = 0; i < amount; i++){
      productTiles.push(<ProductTile />)
    }
    return productTiles;
  }

  return (
    <Container fluid>
      <Row>
        <Col className="hidden" sm={3} md={3} xl={3} lg={3} >
          
        </Col>

        <Col sm={9} md={9} xl={9} lg={9}>
          <Row>
              <Col sm={6} md={6} xl={6} lg={6}>
                <img className="profile--img" src={testpic} alt="test" height="200" width="200" style={{
                borderRadius: '8px',
                padding: '2px',
                }} />
              </Col>
              <Col sm={6} md={6} xl={6} lg={6}>
                <img className="profile--img" src={testpic2} alt="test" height="200" width="200" style={{
                  borderRadius: '8px',
                  padding: '2px',
                }} />
              </Col>
            <Col sm={11} md={11} lg={11}>
              <p style={{ fontSize: "14px", textAlign:"justify", padding: "8px"}}> Beschreibung des Hofes etc Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. </p>
              <hr />
            </Col>            
          </Row>

          <Row>
            <Col sm={11}>
              <Tab.Container id="profile--more-tabs" defaultActiveKey="more">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link  eventKey="more">Mehr</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="contact">Kontakt</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="more">
                        <p style={{fontSize:"14px"}}>No longer mourn for me when I am dead Than you shall hear the surly sullen bell Give warning to the world that I am fled From this vile world with vilest worms to dwell: Nay, if you read this line, remember not The hand that writ it, for I love you so, That I in your sweet thoughts would be forgot, If thinking on me then should make you woe. O! if,--I say you look upon this verse, When I perhaps compounded am with clay,</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="contact">
                        <p style={{fontSize:"14px"}}>No longer mourn for me when I am dead Than you shall hear the surly sullen bell Give warning to the world that I am fled From this vile world with vilest worms to dwell: Nay, if you read this line, remember not The hand that writ it, for I love you so, That I in your sweet thoughts would be forgot, If thinking on me then should make you woe. O! if,--I say you look upon this verse, When I perhaps compounded am with clay,</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
                <hr />
              </Tab.Container>
            </Col>
          </Row>

        </Col>
      </Row>

      <Row>
        <Col className="hidden" sm={4} md={4} xl={3} lg={3} style={{marginTop: "5rem"}} >
          <FilterNavigation />
        </Col>
        
        <Col sm={8} md={8} xl={9} lg={9}>
              <h2>Produkte</h2>
              <CardDeck>
                {renderProducts()}
              </CardDeck>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfilePage;
