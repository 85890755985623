/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Route } from 'react-router-dom';

import Productpage from './pages/Productpage';
import ShoppingcartPage from '../shoppingCart/pages/ShoppingCartPage';
import ProfilePage from '../profile/pages/ProfilePage';



function Product({ match }) {
  return (
    <div>
      <Route exact path={`${match.path}`} component={Productpage} />
      <Route path={`${match.path}/:productId`} component={Productpage} />
      <Route path={`${match.path}/add-to-cart`} component={ShoppingcartPage} />
      <Route path={`${match.path}/more-information`} component={ProfilePage} />
    </div>
  );
}

export default Product;
