/** @jsx jsx */
import { jsx } from "@emotion/core";

function DashboardAddProductTabs6SummaryTextEntry(props, { match }) {

  return (
    <div>
      <h4>{props.name}</h4>
      <p>
        {props.value}
      </p>
    </div>
  );
}

export default DashboardAddProductTabs6SummaryTextEntry;
