
import React from 'react';
import { useState, useEffect } from 'react';
//mport { Link } from 'react-router-dom';

//Bootstrap
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';

// Components
// import ImageGallery from 'react-image-gallery';
import ProductAmoutCounter from '../components/ProductAmountCounter';
import RatingStars from '../../shop/components/RatingStars';
//import { useForm } from '../../shared/hooks/useForm'
import { useMutation } from '@apollo/react-hooks'
import { ADD_PRODUCT_TO_SHOPPINGCART } from '../../shared/ShoppingCartService'
import jwt from 'jsonwebtoken'

// TODO: Verfügbarkeit, Profilabschnitt mit Detail, Mehr Infos, Bewertungen? 


function ProductTop({ props }) {

  var [amount, setAmount] = useState(1);
  var [disabled, setDisabled] = useState(true)

  // const randomImg = "https://source.unsplash.com/528x528/?";

  const user = jwt.decode(window.localStorage.getItem('token'))

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    if (user) setDisabled(false);
  }, [user]);

  /*   const { handleSubmit } = useForm(() => {
      addProductToShoppingCart()
    }) */

  const [addProductToShoppingCart] = useMutation(ADD_PRODUCT_TO_SHOPPINGCART)

  function handleSubmit() {
    addProductToShoppingCart({
      variables: {
        consumerId: user.id,
        productId: props._id,
        productName: props.name,
        producerId: props.producerID,
        amount: amount,
        pricePerUnit: props.offerTypes[0].price,
        tax: props.offerTypes[0].tax,
        image: props.images[0].name
      }
    })

    return (
      <div>
        {window.location.assign('/shoppingcart')}
      </div>
    )
  }

  return (
    <div className="Productpage-container" >
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} sm={6}>
              {/* <Image src={randomImg + props.name} thumbnail /> */}
              <Image src={props.images[0].name} thumbnail />
              {/* <ImageGallery items={images} showNav={false} showFullscreenButton={false} showPlayButton={false}/> */}
            </Col>

            <Col xs={6} sm={6}>
              <Row>
                <Col sm={12}>
                  <h3 style={{ letterSpacing: "2px" }}>{props.name} </h3>
                  <RatingStars amount={5} />
                  <p style={{ fontSize: '16px' }}>{props.producerID}</p>

                  <p style={{ fontSize: '14px' }}>
                    {props.description}
                  </p>

                  {/* TODO: iterate through offertypes and generate dropdown */}
                  <h3>{`${props.offerTypes[0].price}€ / ${props.offerTypes[0].amount} ${(props.offerTypes[0].unit).toLowerCase()}`}</h3>
                  <p style={{ fontSize: '12px' }}>{`inkl. ${props.offerTypes[0].tax}% MwSt., zzgl. Versandkosten`}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={4} lg={4}>
                  <ProductAmoutCounter props={{ amount, setAmount }} />
                </Col>
                <Col sm={12} md={8} lg={8}>
                  {/* <Link to={{ pathname: '/shop/to-product', state: { productId: props._id } }}> */}
                  <Button variant="primary" onClick={handleSubmit} disabled={disabled} to="/product/add-to-cart">Zum Warenkorb hinzufügen</Button>
                  {/*  </Link> */}

                </Col>
              </Row>
            </Col>

          </Row>
        </Form>
      </Container>
    </div>
  );
}

export default ProductTop;

  // const images = [
  //   {
  //     original: 'https://picsum.photos/id/1080/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1080/250/150/',
  //   },
  //   {
  //     original: 'https://picsum.photos/id/1080/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1080/250/150/',
  //   },
  //   {
  //     original: 'https://picsum.photos/id/1080/1000/600/',
  //     thumbnail: 'https://picsum.photos/id/1080/250/150/',
  //   },
  // ];
