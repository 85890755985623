import React from 'react';
//import { useState } from 'react'
// import {Link} from 'react-router-dom';

//Bootstrap
import { Container, Alert } from 'react-bootstrap';

// Components
import ProductTop from '../components/ProductTop';
import ProductBottom from '../components/ProductBottom';
import { useQuery } from '@apollo/react-hooks'
import { GET_PRODUCTSBYID } from '../../shared/ProductService';
// import ImageGallery from 'react-image-gallery';




function Productpage( {match} ) {
  const productId = match.params.productId;
  console.log (productId)

  //const { productId } = props.location.state
  const { data, error, called, loading } = useQuery(GET_PRODUCTSBYID, {
    variables: { _id: productId }
  })

  // Wait for lazy query
  if (called && loading) return (
    <div className="progress">
      <div className="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
    </div>)


  // Show error message if lazy query fails
  if (error) return <p>{error.message}</p>

  console.log(data)

  if (data.getProductById != null) {
    return (

      <div className="Productpage-container" style={{ margin: '11px' }}>
        <Container>
          <ProductTop props={data.getProductById} />
          <hr />
          <ProductBottom props={data.getProductById} />
        </Container>
      </div>
    );
  } else {
    return (
      <Alert key={1} variant={"danger"}>
        404 NOT FOUND!
      </Alert>
    )

  }


}

export default Productpage;
