/** @jsx jsx */
import { jsx } from "@emotion/core";

function SiteFooterText(props) {
  return (
    <p css={theme => ({
      fontSize: '1rem',
      color: theme.colors.white,
      marginBottom: 0,

      [theme.mq('sm')]: {
        fontSize: '1.5rem'
      }
    })}>
      {props.renderText()}
    </p>
  );
}

export default SiteFooterText;
