import React from 'react';
// import { useState, useEffect } from 'react';
import { Button, Form,  } from 'react-bootstrap';
import { Container, Row } from 'react-bootstrap';
import {Link} from 'react-router-dom';
// import FilteredShoppage from "../shop/pages/FilteredShopPageByCategory";
// import Shoppage from '../shop/pages/Shoppage';


import iconBag from '../shared/assets/icons_bag.png';
import iconMarker from '../shared/assets/icons_zip.png';
import iconFood from '../shared/assets/icon_food.png';
import iconIdea from '../shared/assets/icons_idea.png';

import DashboardBaseTest from '../dashboard/components/DashboardBase';

import './style.css'

function Home(props) {
  // const [searchValue, setValue] = useState();
  // const [isFiltered, setFiltered] = useState();

  // useEffect(() => {
  //   setValue(props.searchVal);
  //   setFiltered(props.isFiltered);
  // }, [props.searchVal, props.isFiltered])
  
  // console.log("Home: " + searchValue)

  return (
    <div className="Home container main-content">
    <Container fluid>
      <Row className="container_tips_jumbo1">
        
        <div className="tips_jumbo1">
          <img src={iconIdea} alt="" className="tips_jumbo1_img" />
          <p className="tips_jumbo1_txt">Als Erzeuger können Sie ihre Produkte bequem von zuhause anbieten und verkaufen.</p>
        </div>
        
        <div className="tips_jumbo1">
          <img src={iconIdea} alt="" className="tips_jumbo1_img"/>
          <p className="tips_jumbo1_txt">Kaufen Sie lokale Produkte von Landwirten aus Ihrer Nähe. Ganz einfach - über den Onlineshop.</p>
        </div>
        
        <div className="tips_jumbo1">
          <img src={iconIdea} alt="" className="tips_jumbo1_img"/>
          <p className="tips_jumbo1_txt">Lassen Sie sich ihre bestellten Waren zu einer Abholstation in Ihrer Nähe liefern. Dort wird die Ware frisch für sie angeliefert.</p>
        </div>
       
      </Row>

      <Row>
        {/* ZipCode  */}
        <div className="home_zipcode_input_container">
        <Form >
          <Form.Group controlId="formPLZ">
          <Form.Label>Erzeuger in deiner Nähe anzeigen</Form.Label>
          <Form.Control type="numbers" placeholder="Postleitzahl eingeben" maxlength="5"/>
          <Link to="/shop">
            <Button variant="primary" className="home_zipcode_input_element" >PLZ überprüfen</Button>
          </Link>
          </Form.Group>
        </Form>
        </div>
      </Row>

      <Row>
        {/* So funktionierts */}
        <div className="howto_container">
          <p>So funktionierts:</p>
        <div className="home_howto_container">
          <div className="home_howto_container_element">
            <img src={iconMarker} alt="" className="home_howto_container_element_img" />
            <p className="home_howto_container_element_txt">1. Gib deine Postleitzahl ein</p>
          </div>
          
          <div className="home_howto_container_element">
            <img src={iconFood} alt="" className="home_howto_container_element_img" />
            <p className="home_howto_container_element_txt">2. Produkte auswählen</p>
          </div>
          
          <div className="home_howto_container_element">
            <img src={iconBag} alt="" className="home_howto_container_element_img"/>
            <p className="home_howto_container_element_txt">3. Bestellen und Abholen</p>
          </div>
        </div>
        </div>
      </Row>
      
    </Container>  

<DashboardBaseTest />

        {/* {!isFiltered ? (
          <>      
          <Shoppage  /> 
          </>
        ) : (
          <FilteredShoppage category={searchValue} />  
        )} */}
    </div>
  );
}

export default Home;
