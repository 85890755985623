/** @jsx jsx */
import { jsx } from "@emotion/core";
import {Link} from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";

import './shop_styles.css';

function FilterNavigation(props) {

  return (
    <Container>
      <Row>
        <Col>
          <div className="navigation_categories_head">
            <p css={{fontSize:"14px", fontWeight:"bold", padding: "6px"}}>Kategorien</p>
          </div>
          
          <div className="navigation_categories_container">
            <Link id="non-alcoholic" to="/shop/category/non-alcoholic" className="navigation_categories">Getränke</Link><br></br>
            <Link id="vegetables" to="/shop/category/vegetables" className="navigation_categories">Gemüse</Link><br></br>
            <Link id="fruits" to="/shop/category/fruit" className="navigation_categories">Obst</Link><br></br>
            <Link id="oils" to="/shop/category/oils" className="navigation_categories">Öle</Link><br></br>
            <Link id="meat" to="/shop/category/meat" className="navigation_categories">Fleisch</Link><br></br>
            <Link id="spices" to="/shop/category/spices" className="navigation_categories">Gewürze</Link><br></br>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FilterNavigation;
