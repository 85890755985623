import { gql } from 'apollo-boost';

const GET_ACCOUNT = gql`
  query getAccount(
    $id: ID!){
    getAccount(_id: $id){
      name
      surname
      email
      address{
        street_name
        street_number
        city
        zip_code
        country
      }
    }
  }
`

const MODIFY_ACCOUNT = gql`
    mutation modifyAccount(
    $id: ID!,
    $name: String!, 
    $surname: String!,
    $email: String!, 
    $street_name: String!, 
    $street_number: String!,
    $city: String!,
    $zip_code: Int!,
    $country: String!){
        editAccount(_id: $id, userInput:{
          name:$name 
          surname:$surname
          email:$email
          address:{
            street_name: $street_name
            street_number: $street_number
            city: $city
            zip_code: $zip_code
            country: $country
          }
          type: ADMIN
          }){
          _id
          name
          surname
          email
          address{
              street_name
              street_number
              city
              zip_code
              country
          }
          type
        }
    }
`

export {
  GET_ACCOUNT,
  MODIFY_ACCOUNT
} 
