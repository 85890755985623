/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Route, Redirect } from 'react-router-dom';

import ShoppingCartpage from './pages/ShoppingCartPage';
import ProductPage from '../product/pages/Productpage';
import ProfilePage from '../profile/pages/ProfilePage';

function ShoppingCart({ match }) {
  if (window.localStorage.getItem('token')) {
    return (
      <div>
        <Route exact path={`${match.path}`} component={ShoppingCartpage} />
        <Route path={`${match.path}/to-product`} component={ProductPage} />
        <Route path={`${match.path}/to-profile`} component={ProfilePage} />
        <Route path={`${match.path}/order`} component={ShoppingCartpage} />
      </div>
    );
  } else {
    return (
      <div>
        <Redirect to="../auth/login" />
      </div>
    )
  }
}

export default ShoppingCart;
