/** @jsx jsx */
import { jsx } from "@emotion/core";
// import { useState } from 'react';

import star_empty from '../../shared/assets/star_empty.png';
import star_full from '../../shared/assets/star_full.png';
// import star_half from '../../shared/assets/star_half.png';


function RatingStars(props) {
  // const [amount, setAmount] = useState(0);

  function renderStars() {
    const max = 5;
    var roundedAmount = Math.round(props.amount, 1);
    var starList = [];


    for (var i = 0; i < roundedAmount; i++) {
      starList.push(<img src={star_full} alt=" " key={"full_"+roundedAmount} width='10%' /> );
    }

    if (roundedAmount < max) {
      for (var j = roundedAmount; j < max; j++){
        starList.push(<img src={star_empty} alt=" " key={"empty_"+roundedAmount} width='10%' />)
      }
    }
    return starList;
  }


  return (
    <div className="Rating-Container" css={{ width: '150px' }}>
      <div>{renderStars()}</div>
    </div>
  );
}

export default RatingStars;

      // {Array(props.amount).fill(1).map((el, i) =>
      //   <img src={star_full} alt=" " width='10%' />
        
      // )} 
