/** @jsx jsx */
import { jsx } from "@emotion/core";

import SiteFooterText from './SiteFooterText';
import SiteFooterLink from './SiteFooterLink';

function SiteFooter() {
  return (
    <footer className="SiteFooter" css={theme => ({
      flexShrink: 0,
      textAlign: 'center',
      backgroundColor: "#2DB997",
      overflow: 'hidden',
      paddingTop: theme.margins.componentDefault,
      paddingBottom: theme.margins.componentDefault,
      marginTop: theme.margins.componentDefault
    })}>
      <div className="container">
        <SiteFooterText renderText={() => "© 2020 - Q"} />
        <SiteFooterText renderText={() => [<SiteFooterLink text="Impressum" to="/impressum" />, " | ", <SiteFooterLink text="Datenschutz" to="/datenschutz" />]} />
      </div>
    </footer>
  );
}

export default SiteFooter;
