/** @jsx jsx */
import { jsx } from "@emotion/core";
import ClipLoader from 'react-spinners/ClipLoader';

// Components
import ShopProductsCardDeck from './ShopProductsCardDeck';
import FilterNavigation from './FilterNavigation';
import ZipCodeFilter from './ZipCodeFilter';
import './shop_styles.css';

// Bootstrap
import { Row, Col, Container } from 'react-bootstrap';

function handleFilter(filter) {
  console.log(filter);
}

function ShopProducts( {categoryPage, category, match, loading, error, data}) {
  if (loading) return <div className="text-center"><ClipLoader size={150} color={"#00A347"} loading={true}></ClipLoader></div>;
  if (error) return <p>{error.message}</p>;
  

  if (data) {
    var dataProducts = [];
    if (data.getProductsByCategory) {
      dataProducts = data.getProductsByCategory;
    } else if (data.getAllProducts) {
      dataProducts = data.getAllProducts;
    }
    return (
      <Container>
        <Row className="shop_products_container">
          <Col>
            <FilterNavigation handleFilter={handleFilter.bind(this)}/>
          </Col>

          
          <Col sm={10} className="shop_products_scrollpage">
            {(category) &&
              <h2>Kategorie: {category}</h2>
            }
            {(dataProducts.length === 0) ? (
              <Fallback />
            ) : (
              <div>
                <Row>
                  <ZipCodeFilter className="zipcodefilter"/>
                </Row>
                <Row>
                  <ShopProductsCardDeck data={dataProducts} />
                </Row>
              </div>
          )}
          </Col>
        </Row>
      </Container>
    );
    } else {
      return <h2>Produkte können derzeit nicht abgefragt werden.</h2>
    }
}

function Fallback() {
  return (
    <div className="fallback-section">
      <h3>Keine Produkte vorhanden</h3>
    </div>
  );
}

export default ShopProducts;
