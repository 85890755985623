import { gql } from 'apollo-boost';

const GET_ALLPRODUCTS = gql`
  query getAllProducts{
    getAllProducts{
      _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const GET_PRODUCTSBYID = gql`
  query getProductById($_id:ID!){
    getProductById(_id: $_id){
      _id
      producerID
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const GET_PRODUCTSBYPRODUCER = gql`
  query getProductsByProducer($producerID: String!){
    getProductsByProducer(producerID: $producerID){
      _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const GET_PRODUCTSBYCOLOR = gql`
  query getProductsByColor($color: string!){
    getProductsByColor(color: $color){
      _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const GET_PRODUCTSBYCATEGORY = gql`
  query getProductsByCategory($category: Category!){
    getProductsByCategory(category: $category){
      _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const GET_PRODUCTSBYCATEGORYFILTER = gql`
  query getProductsByCategory($category: Category!){
    getProductsByCategory(category: $category) {
      _id
      producerID
      name
      description
      images {
        name
        resolutions
      }
      offerTypes{
        price
        amount
        unit
      }
    }
  }
`;

const GET_PRODUCTSBYBRAND = gql`
  query getProductsByBrand($brand: string!){
    getProductsByBrand(brand: $brand){
      _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const GETPRODUCTSBYNAME = gql`
  query getProductsByName($name: string!){
    getProductsByName(name: $name){
      _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const DELETE_PRODUCTBYID = gql`
  mutation deleteProduct($producerID: ID!, $_id: ID!){
    deleteProduct(producerID: $producerID, _id: $_id){
      _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct(
    $producerID: String!,
    $name: String!,
    $description: String!,
    $longDescription: String!,
    $offerTypes: [OfferTypeInput!]!,
    $brand: String,
    $color: String,
    $category: Category!,
    $packaging: Packaging!,
    $condition: Condition!,
    $allergen: [Allergen!],
    $additive: [Additive!],
    $foodSafety: [FoodSafety!],
    $images: [ImageInput!]
  ){
    createProduct(createProductInput:{
    producerID: $producerID,
    name: $name,
    description: $description,
    longDescription: $longDescription,
    offerTypes: $offerTypes,
    brand: $brand,
    color: $color,
    category: $category,
    packaging: $packaging,
    condition: $condition,
    allergen: $allergen,
    additive: $additive,
    foodSafety: $foodSafety,
    images: $images
}){
      _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
  }
}
`;

const UPDATE_PRODUCT = gql`
mutation updateProduct($producerID: ID!, $_id: ID!, $input: updateProductInput!) {
  updateProduct(producerID: $producerID, _id:$_id,
    input: $input
  ) {
    _id
      name
      description
      longDescription
      offerTypes{
        price
        amount
        unit
        tax
        discount
      }
      category
      images{
        name
        resolutions
      }
      allergen
      additive
      foodSafety
      color
      packaging
      condition
  }
}
`;

export {
  GET_ALLPRODUCTS,
  GET_PRODUCTSBYID,
  GET_PRODUCTSBYPRODUCER,
  GET_PRODUCTSBYCOLOR,
  GET_PRODUCTSBYCATEGORY,
  GET_PRODUCTSBYCATEGORYFILTER,
  GET_PRODUCTSBYBRAND,
  GETPRODUCTSBYNAME,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCTBYID

};
