/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useMutation } from '@apollo/react-hooks';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { REGISTER_ACCOUNT } from '../components/AuthService/AuthService';
import Button from 'react-bootstrap/Button';
import { useForm } from '../../shared/hooks/useForm';
import { Redirect } from 'react-router-dom';

function AuthRegister() {
  // Use form state
  const { values, handleChange, handleSubmit } = useForm(() => {
    values.zip_code = parseInt(values.zip_code)
    values.type = document.getElementById('formType').value
    // values.type = formatEnum(values.type)
    if(values.password !== values.passwordRepeat){
      alert('Passwörter stimmen nicht überein.')
    }else{
      registerUser()
    }
  } );

  // Lazy query for register user method
  const [registerUser, { called, loading, data, error }] = useMutation(REGISTER_ACCOUNT, { variables: values })


  // Wait for lazy query
  if (called && loading) return <p>lädt...</p>

  // Show error message if lazy query fails
  if (error) return <p>{error.message} </p>

  if (data) {
    return <Redirect to='/auth/login' />
  }

  return (

    <Container>
      <h1>Registrieren</h1>

      <Col md="6" className="mx-auto">
        <p>Vielen Dank für Ihr Interesse an unserem Webshop!</p>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPersonal">
            <Form.Label>Anmeldedaten</Form.Label>
            <Form.Group controlId="formName">
              <Form.Control type="text" name="name" placeholder="Name" onChange={handleChange} value={values.name} required />
            </Form.Group>

            <Form.Group controlId="formSurname">
              <Form.Control type="text" name="surname" placeholder="Nachname" onChange={handleChange} value={values.surname} required />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Control type="email" name="email" placeholder="E-Mail Adresse" onChange={handleChange} value={values.email} required />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Control type="password" name="password" placeholder="Passwort" onChange={handleChange} value={values.password} required />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Control type="password" name="passwordRepeat" placeholder="Passwort wiederholen" onChange={handleChange} value={values.passwordRepeat} required />
            </Form.Group>
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label>Addresse</Form.Label>
            <Form.Group controlId="formStreetName">
              <Form.Control type="text" name="street_name" placeholder="Straße" onChange={handleChange} value={values.street_name} required />
            </Form.Group>
            <Form.Group controlId="formStreetNumber">
              <Form.Control type="text" name="street_number" placeholder="Straßennummer" onChange={handleChange} value={values.street_number} required />
            </Form.Group>
            <Form.Group controlId="formCity">
              <Form.Control type="text" name="city" placeholder="Stadt" onChange={handleChange} value={values.city} required />
            </Form.Group>
            <Form.Group controlId="formZIPCode">
              <Form.Control type="number" name="zip_code" placeholder="Postleitzahl (PLZ)" onChange={handleChange} value={values.zip_code} required />
            </Form.Group>
            <Form.Group controlId="formStreetName">
              <Form.Control type="text" name="country" placeholder="Land" onChange={handleChange} value={values.country} required />
            </Form.Group>
          </Form.Group>

          {/* other mechanism for role assignment required, e.g per e-mail */}
          <Form.Group controlId="formType">
            <Form.Label>Anmelden als</Form.Label>
            <Form.Control as="select" name="type" onChange={handleChange} values={values.type}>
              <option>CONSUMER</option>
              <option>PRODUCER</option>
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit">
            Registrieren
        </Button>
        </Form>
      </Col>
    </Container>
  );
}

export default AuthRegister;
