/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Route } from 'react-router-dom';

import Login from './pages/AuthLogin';
import Register from './pages/AuthRegister';

function Auth({ match }) {
  return (
    <div>
      <Route path={`${match.path}/login`} component={Login} />
      <Route path={`${match.path}/reset-password`} component={Login} />
      <Route path={`${match.path}/register`} component={Register} />
    </div>
  );
}

export default Auth;
