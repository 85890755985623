import { gql } from 'apollo-boost';

const ADD_PRODUCT_TO_SHOPPINGCART = gql`
mutation addProductToShoppingCart(
  $consumerId: ID!, 
  $productId: ID!, 
  $productName: String!,
  $producerId: ID!,
  $amount: Float!,
  $pricePerUnit: Float!,
  $tax: Float!,
  $image: String!
){
  addProduct(editShoppingCartProductsInput:{
    consumerId: $consumerId,
    productId: $productId,
    productName: $productName,
    producerId: $producerId,
    amount: $amount,
    pricePerUnit: $pricePerUnit,
    tax: $tax,
    image: $image
  })
  {
    _id
  }
}
`

const GET_SHOPPINGCART = gql`
query getShoppingCart($consumerId: ID!){
  getShoppingCartByConsumer(getShoppingCartByConsumerInput: { consumerId: $consumerId }){
    _id
    consumerId
    products{
      productId
      producerId
      productName
      amount
      pricePerUnit
      tax
      image
    }
  }
}
`

const MODIFY_PRODUCT_AMOUNT = gql`
mutation modifyProductAmount($consumerId: ID!, $productId: ID!, $amount: Float!) {
  modifyProductAmount(
    editShoppingCartProductAmount: {
        consumerId: $consumerId
        productId: $productId
        amount: $amount
    }
  ) {
    _id
    consumerId
    products {
      productId
      pricePerUnit
      amount
    }
  }
}
`

const EMPTY_SHOPPINGCART = gql`
mutation emptyShoppingCart($consumerId: ID!){
  emptyShoppingCart(editShoppingCartInput: { consumerId: $consumerId }){
    _id
    consumerId
  }
}
`

export {
  ADD_PRODUCT_TO_SHOPPINGCART,
  GET_SHOPPINGCART,
  MODIFY_PRODUCT_AMOUNT,
  EMPTY_SHOPPINGCART
};
