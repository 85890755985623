import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, FormControl, Image, InputGroup, ListGroup, Button } from 'react-bootstrap';
import { useMutation } from '@apollo/react-hooks'
import { MODIFY_PRODUCT_AMOUNT } from '../../shared/ShoppingCartService'

function ShoppingCartProductTile({ props }) {

  function handleDelete(event) {
    modifyProductAmount({
      variables: {
        consumerId: userId,
        productId: productId,
        amount: 0
      }
    })
    window.location.assign('')
  }

  function handleIncrease(event) {
    modifyProductAmount({
      variables: {
        consumerId: userId,
        productId: productId,
        amount: amount+1
      }
    })
  }

  function handleDecrease(event){
    modifyProductAmount({
      variables: {
        consumerId: userId,
        productId: productId,
        amount: amount-1
      }
    }) 
  }

  let { userId, amount, pricePerUnit, productId, producerId, productName } = props.element

  // const randomImg = "https://source.unsplash.com/528x528/?";

  const [modifyProductAmount] = useMutation(MODIFY_PRODUCT_AMOUNT);

  return (

    <ListGroup.Item>
      <Container fluid>
          <Row>
            <Col sm={{ span: 2, offset: 0 }}>
              
              <Image src={props.element.image} thumbnail />
              {/* <Image src={randomImg + productName} thumbnail /> */}
            </Col>
            <Col sm={{ span: 4, offset: 1 }}>
              <Row>
                <p style={{fontWeight: "bold"}}>{productName}</p>
              </Row>
              <Row>
                <p>Artikel Nr: {productId}</p>
              </Row>
              <Row>
                <Link to="/shoppingcart/to-profile">Erzeuger: {producerId}</Link>
              </Row>
            </Col>
            <Col sm={{ span: 3, offset: 0 }}>
              <Form.Label css={{ textAlign: "center" }}>Menge</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <Button variant="outline-secondary" size='sm' onClick={handleDecrease} css={{
                    minWidth: "25px",
                    maxWidth: "100px",
                    boxRadius: '5px 0px 0px 5px',
                    color: 'black',
                  }}>-</Button>
                </InputGroup.Prepend>
                <FormControl size='md' value={amount}
                  css={{
                    minWidth: "50px",
                    maxWidth: "35px",
                  }}
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary" size='sm' onClick={handleIncrease} css={{
                    minWidth: "25px",
                    maxWidth: "100px",
                    borderRadius: '0px 5px 5px 0px',
                    color: 'black',
                  }}>+</Button>
                </InputGroup.Append>
              </InputGroup>

            </Col>
            <Col sm={{ span: 2, offset: 0 }}>
              <Row>
                <p>Preis: €{parseFloat(pricePerUnit * amount).toFixed(2)}</p>
              </Row>
              <Row>
                <p>Preis/Stück: € {parseFloat(pricePerUnit).toFixed(2)}</p>
              </Row>
              <Row>
                <Button variant="primary" type="button" onClick={handleDelete}>Entfernen</Button>
              </Row>
            </Col>
          </Row>
      </Container>
    </ListGroup.Item>
  );
}

export default ShoppingCartProductTile;
