import React from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { useQuery } from '@apollo/react-hooks'
import { GETORDERBYCONSUMER } from '../../shared/OrderService';
import { useState } from 'react'
import jwt from 'jsonwebtoken'

function AccountOrderTab() {

  const user = jwt.decode(window.localStorage.getItem('token'))

  const { data } = useQuery(GETORDERBYCONSUMER, {
    variables: { consumerId: user.id }
  });


  const dataTableData = []

  if (data) {
    data.getOrdersByConsumer.forEach(element => {
      let orderData = {
        _id: element._id,
        producer: element.producer,
        total: `€ ${element.total}`,
        status: element.orderStatus[element.orderStatus.length - 1].status,
        date: element.orderStatus[element.orderStatus.length - 1].date
      }
      dataTableData.push(orderData)
    });
  }


  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: dataTableData.length
    }]
  };

  function getProductsOfOrder(order) {
    var tableOfProducts = []
    var orderArray = data.getOrdersByConsumer
    var orderId = order
    var thisOrder = orderArray.find(order => order._id === orderId)

    thisOrder.products.forEach(element => {
      tableOfProducts.push({
        productId: element.productId,
        productName: element.productName,
        amount: element.amount,
        price: `€ ${element.price}`
      })
    })
    return tableOfProducts
  }

  function getBillData(order) {
    var billTable = []
    var orderArray = data.getOrdersByConsumer
    var orderId = order
    var thisOrder = orderArray.find(order => order._id === orderId)

    thisOrder.products.forEach(element => {
      billTable.push({
        productId: element.productId,
        productName: element.productName,
        amount: element.amount,
        tax: element.tax,
        pricePerPiece: `€ ${parseFloat(element.price / element.amount).toFixed(2)}`,
        total: `€ ${element.price}`
      })
    })
    return billTable
  }

  const orderColumns = [{
    dataField: '_id',
    text: 'Bestellung'
  }, {
    dataField: 'producer',
    text: 'Erzeuger'
  }, {
    dataField: 'total',
    text: 'Total'
  }, {
    dataField: 'status',
    text: 'Status'
  }, {
    dataField: 'date',
    text: 'Datum'
  }];

  const productColumns = [{
    dataField: 'productId',
    text: 'ID'
  }, {
    dataField: 'productName',
    text: 'Produkt'
  }, {
    dataField: 'amount',
    text: 'Anzahl'
  }, {
    dataField: 'price',
    text: 'Preis'
  }];

  const billColumns = [{
    dataField: 'productId',
    text: 'ID'
  }, {
    dataField: 'productName',
    text: 'Produkt'
  }, {
    dataField: 'amount',
    text: 'Anzahl'
  }, {
    dataField: 'tax',
    text: 'Mwst.'
  }, {
    dataField: 'pricePerPiece',
    text: 'Preis/Stück'
  }, {
    dataField: 'total',
    text: 'Gesamt'
  }];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const expandRow = {
    renderer: row => (
      <Card>
        <Card.Body>
          <Card.Text>
            <BootstrapTable
              keyField='productId'
              data={getProductsOfOrder(row._id)}
              columns={productColumns}
              pagination={paginationFactory(options)}
            />
          </Card.Text>
          <>
            <Button variant="primary" onClick={handleShow}>
              Rechnung anzeigen
            </Button>

            <Modal show={show} name="show" onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header closeButton>
                <Modal.Title>{`Rechnung für Bestellung ${row._id}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BootstrapTable
                  keyField='productId'
                  data={getBillData(row._id)}
                  columns={billColumns}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Schließen
          </Button>
                <Button variant="primary" onClick={handleClose}>
                  PDF exportieren
          </Button>
              </Modal.Footer>
            </Modal>
          </>
        </Card.Body>
      </Card>
    ),
    showExpandColumn: true
  };



  return (

    <div className="Account order container" style={{ margin: '11px' }}>
      <BootstrapTable
        keyField='_id'
        data={dataTableData}
        columns={orderColumns}
        expandRow={expandRow}
        pagination={paginationFactory(options)}
      />
    </div>
  );
}

export default AccountOrderTab;
