/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button, Row, Col } from 'react-bootstrap';

import DashboardAddProductTabs6SummaryTextEntry from './DashboardAddProductTabs6SummaryTextEntry';

function DashboardAddProductTabs6Summary(props, { match }) {

  return (
    <div>
      <h2>Zusammenfassung</h2>
      <p className="lead">Bevor das Produkt im Shop veröffentlicht wird, werden auf dieser Seite einmal alle eingegebenen Daten zur Überprüfung angezeigt.</p>

      <Row>
        <Col md={6}>
          <h3>Basis-Daten <Button variant="primary" size="sm" type="button" onClick={((e) => props.handleNextStepClick(2))}>Ändern</Button></h3>
          
          <DashboardAddProductTabs6SummaryTextEntry name="Titel" value={props.values.name} />

          <DashboardAddProductTabs6SummaryTextEntry name="Kurzbeschreibung" value={props.values.description} />

          <DashboardAddProductTabs6SummaryTextEntry name="Detaillierte Beschreibung (optional)" value={props.values.descriptionLong} />

          <DashboardAddProductTabs6SummaryTextEntry name="Kategorie" value={props.values.category} />
        </Col>
        <Col md={6}>
          <h3>Produktfoto <Button variant="primary" size="sm" type="button" onClick={((e) => props.handleNextStepClick(3))}>Ändern</Button></h3>
          <img css={theme => ({
            maxWidth: "100%",
            marginBottom: theme.margins.componentDefault
          })} src={props.values.image} alt="Produktfoto" /><br />
        </Col>
      </Row>

      <h3>Preise & Menge <Button variant="primary" size="sm" type="button" onClick={((e) => props.handleNextStepClick(4))}>Ändern</Button></h3>
      <Row>
        <Col md={6}>
          <DashboardAddProductTabs6SummaryTextEntry name={`Preis für ${props.values.amount} ${props.values.unit}`} value={`${props.values.amountPrice} €`} />
        </Col>
        <Col md={6}>
          <DashboardAddProductTabs6SummaryTextEntry name="Gesamtmenge" value={`${props.values.amountTotal} ${props.values.unit}`} />
        </Col>
      </Row>

      <h3>Optionale Details <Button variant="primary" size="sm" type="button" onClick={((e) => props.handleNextStepClick(5))}>Ändern</Button></h3>
      <Row>
        <Col md={6}>
          <DashboardAddProductTabs6SummaryTextEntry name="Marke" value={props.values.brand} />
          <DashboardAddProductTabs6SummaryTextEntry name="Farbe" value={props.values.color} />
          <DashboardAddProductTabs6SummaryTextEntry name="Lebensmittelsicherheit" value={props.values.foodSafety} />
          <DashboardAddProductTabs6SummaryTextEntry name="Allergen" value={props.values.allergen} />
        </Col>
        <Col md={6}>
          <DashboardAddProductTabs6SummaryTextEntry name="Zustand" value={props.values.condition} />
          <DashboardAddProductTabs6SummaryTextEntry name="Verpackung" value={props.values.packaging} />
          <DashboardAddProductTabs6SummaryTextEntry name="Zusatzstoffe" value={props.values.additive} />
        </Col>
      </Row>

      <div className="text-center">
        <Button size="lg" variant="primary" onClick={((e) => props.publishProduct())} type="submit">Produkt veröffentlichen</Button>
      </div>
    </div>
  );
}

export default DashboardAddProductTabs6Summary;
