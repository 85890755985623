import React from 'react';
import { useQuery } from '@apollo/react-hooks';

// Components
import ShopProducts from '../components/ShopProducts';
import { GET_PRODUCTSBYCATEGORYFILTER } from '../../shared/ProductService';

function FilteredShopPageByCategory({match}){
  const categorySlug = match.params.categorySlug;
  const category = categorySlug.toUpperCase().replace('-', '_');

  const params = {
    category
  }
  const { loading, error, data } = useQuery(GET_PRODUCTSBYCATEGORYFILTER, {
    variables: params,
  });

  return (
    <ShopProducts categoryPage={true} category={category} loading={loading} error={error} data={data} />
  );
}

export default FilteredShopPageByCategory;
