/** @jsx jsx */
import { jsx } from "@emotion/core";
import {Link} from 'react-router-dom';

function SiteFooterLink(props) {
  return (
    <Link css={theme => ({
      color: theme.colors.white,
      ':hover,:focus': {
        color: theme.colors.graySemilight
      }
    })} to={props.to}>
      {props.text}
    </Link>
  );
}

export default SiteFooterLink;
