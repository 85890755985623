/** @jsx jsx */
import { jsx } from "@emotion/core";
import './Styles.css'
import { Container, Row, Col } from 'react-bootstrap';

// Components 
import SiteHeaderLogo from './SiteHeaderLogo';
import SiteHeaderUserNavigation from './SiteHeaderUserNavigation';
import headerPicture from './assets/headerpicture.jpg';

function SiteHeader(props) {

  // function handleSearchString(searchValue, isFiltered) {
  //   props.handleSearch(searchValue, isFiltered);
  // }
  
  return (
    <Container>
      <Row>
        <Col>
          <SiteHeaderLogo />
          <SiteHeaderUserNavigation />
        </Col>
      </Row>
      <Row>
          <div  className="header_jumbotron" >
            <img src={headerPicture} alt="header" className="header_container_pic" /> 
            {/* <p>Bestelle deine Lebensmittel direkt beim Erzeuger</p> */}
          </div>
      </Row>
    </Container>
  );
}

export default SiteHeader;
