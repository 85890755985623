import React from 'react';



function AccountSettingTab() {
  return (
    <div className="Home container" style={{margin: '11px'}}>


      <hr />
    </div>
  );
}

export default AccountSettingTab;
