import { gql } from 'apollo-boost';

const AUTHENTICATE_ACCOUNT = gql` 
    query authAccount($email: String!, $password: String!){
        authenticateAccount(accountInput:{email:$email password:$password}){
            authToken
        }
    }
`

const REGISTER_ACCOUNT = gql`
    mutation createAccount(
        $name: String!, 
        $surname: String!, 
        $password: String!, 
        $email: String!, 
        $street_name: String!, 
        $street_number: String!, 
        $city: String!, 
        $zip_code: Int!, 
        $country: String!,
        $type: AccountType!){
        createAccount(userInput:{
            name:$name,
            surname:$surname, 
            password:$password, 
            email:$email, 
            address:{
                street_name: $street_name,
                street_number: $street_number,
                city: $city,
                zip_code: $zip_code,
                country: $country
            }, 
            type:$type}){
            _id
        }
    }
`

export {
    AUTHENTICATE_ACCOUNT,
    REGISTER_ACCOUNT
};
