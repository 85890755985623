/** @jsx jsx */
import { jsx } from "@emotion/core";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useState } from 'react';
import jwt from 'jsonwebtoken';
import { useMutation } from '@apollo/react-hooks';
// import { Redirect } from 'react-router-dom';

import { useForm } from '../../shared/hooks/useForm';
import { CREATE_PRODUCT } from '../../shared/ProductService';

import DashboardAddProductTabs1Search from './DashboardAddProductTabs1Search';
import DashboardAddProductTabs2Basics from './DashboardAddProductTabs2Basics';
import DashboardAddProductTabs3Images from './DashboardAddProductTabs3Images';
import DashboardAddProductTabs4Price from './DashboardAddProductTabs4Price';
import DashboardAddProductTabs5Details from './DashboardAddProductTabs5Details';
import DashboardAddProductTabs6Summary from './DashboardAddProductTabs6Summary';

function DashboardAddProductTabs({ match }, props) {
  const [key, setKey] = useState('product-add-step-1');

  const user = jwt.decode(window.localStorage.getItem('token'));

  const { values, handleChange } = useForm(() => {
    values.type = document.getElementById('formType').value;
  } );

  const productAddDisabledSteps = {
    s2: false,
    s3: false,
    s4: false,
    s5: false,
    s6: false
  }

  function setValuesFromTemplate(templateData) {
    values.name = templateData.title;
    values.description = templateData.description;
    values.descriptionLong = templateData.longDescription;
    values.image = templateData.img;
    values.additive = templateData.additive[0];
    values.allergen = templateData.allergen[0];
    values.category = templateData.category;
    values.color = templateData.color;
    values.condition = templateData.condition;
    values.foodSafety = templateData.foodSafety[0];
    values.packaging = templateData.packaging;
    values.amount = templateData.offerTypes[0].packagingAmount;
    values.amountPrice = templateData.offerTypes[0].price;
    values.amountTotal = templateData.offerTypes[0].totalAmount;

    switch (templateData.offerTypes[0].templateUnit) {
      case "QUANTITY":
        values.unit = 'Stk.'
        break;
      case "KILOGRAM":
        values.unit = 'kg'
        break;
      case "LITER":
        values.unit = 'l'
        break;
      case "GRAM":
        values.unit = 'g'
        break;
      default:
        break;
    }
  }

  const handleClick = (event, templateProps) => {
    handleNextStepClick(2);
    event.preventDefault();

    setValuesFromTemplate(templateProps);
  }

  const handleTemplateUsage = (event, templateProps) =>{
    handleNextStepClick(2);
    document.body.style.overflow = "scroll"
    
    setValuesFromTemplate(templateProps);
  }

  const handleNextStepClick = (nextStep) =>{
    switch (nextStep) {
      case 2:
        productAddDisabledSteps.s2 = false;
        break;
      case 3:
        productAddDisabledSteps.s3 = false;
        break;
      case 4:
        productAddDisabledSteps.s4 = false;
        break;
      case 5:
        productAddDisabledSteps.s5 = false;
        break;
      case 6:
        productAddDisabledSteps.s6 = false;
        break;
      default:
        break;
    }
    
    setKey('product-add-step-' + nextStep);
  }

  const [apiCreateProduct, { data, error, called, loading }] = useMutation(CREATE_PRODUCT, {
    variables: getSubmitData()
  });

  if (called && loading) return (<p>Veröffentliche Produkt...</p>);

  if (error) return <p>{error.message} </p>

  if (data) {
    alert("Das Produkt wurde veröffentlicht.");
  
    // return <Redirect to="/dashboard" />
    // Using full refresh to force updating dashboard products
    return (
      <div>
        {window.location.assign('/dashboard')}
      </div>
    )
  }

  function getSubmitData() {
    switch (values.unit) {
      case 'g':
        values.amountSubmit = values.amount;
        values.unitSubmit = 'GRAM';
        break;
      case 'ml':
        values.amountSubmit = values.amount;
        values.unitSubmit = 'MILILITER';
        break;
      case 'Stk.':
        values.amountSubmit = values.amount;
        values.unitSubmit = 'PIECR';
        break;
      case 'l':
        values.amountSubmit = values.amount * 1000;
        values.unitSubmit = 'MILILITER';
        break;
      case 'kg':
        values.amountSubmit = values.amount * 1000;
        values.unitSubmit = 'GRAM';
        break;
      default:
        break;
    }
    
    if (values.descriptionLong === null || values.descriptionLong === '') {
      values.descriptionLong = values.description;
    }

    const submitData = {
      producerID: user.id,
      name: values.name,
      description: values.description,
      longDescription: values.descriptionLong,
      offerTypes: [{
        price: parseFloat(values.amountPrice),
        amount: parseFloat(values.amountSubmit),
        unit: values.unitSubmit,
        tax: 7,
        discount: 0
      }],
      brand: values.brand,
      color: values.color,
      category: values.category,
      packaging: values.packaging,
      condition: values.condition,
      allergen: [
        values.allergen
      ],
      additive: [
        values.additive
      ],
      foodSafety: [
        values.foodSafety
      ],
      images: [{
        name: values.image,
        resolutions: 'original'
      }]
    }

    return submitData;
  }

  const publishProduct = () =>{
    apiCreateProduct();
  }

  return (
    <Tabs id="product-add-tabs" activeKey={key} onSelect={k => setKey(k)}>
      <Tab eventKey="product-add-step-1" title="1. Produkt suchen">
        <DashboardAddProductTabs1Search values={values} handleChange={handleChange} handleClick={handleClick} handleTemplateUsage={handleTemplateUsage} handleNextStepClick={handleNextStepClick} />
      </Tab>
      <Tab eventKey="product-add-step-2" title="2. Basis-Daten" disabled={productAddDisabledSteps.s2}>
        <DashboardAddProductTabs2Basics values={values} handleChange={handleChange} handleNextStepClick={handleNextStepClick} />
      </Tab>
      <Tab eventKey="product-add-step-3" title="3. Fotos" disabled={productAddDisabledSteps.s3}>
        <DashboardAddProductTabs3Images values={values} handleChange={handleChange} handleNextStepClick={handleNextStepClick} />
      </Tab>
      <Tab eventKey="product-add-step-4" title="4. Preise & Menge" disabled={productAddDisabledSteps.s4}>
        <DashboardAddProductTabs4Price values={values} handleChange={handleChange} handleNextStepClick={handleNextStepClick} />
      </Tab>
      <Tab eventKey="product-add-step-5" title="5. Details" disabled={productAddDisabledSteps.s5}>
        <DashboardAddProductTabs5Details values={values} handleChange={handleChange} handleNextStepClick={handleNextStepClick} />
      </Tab>
      <Tab eventKey="product-add-step-6" title="6. Zusammenfassung" disabled={productAddDisabledSteps.s6}>
        <DashboardAddProductTabs6Summary values={values} handleChange={handleChange} publishProduct={publishProduct} handleNextStepClick={handleNextStepClick} />
      </Tab>
    </Tabs>
  );
}

export default DashboardAddProductTabs;
