/** @jsx jsx */
import { jsx } from "@emotion/core";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Button } from 'react-bootstrap';

import DashboardAddProductTabs from '../components/DashboardAddProductTabs';

function DashboardAddProduct({ match }) {
  return (
    <Container>
      <h1>Produkt hinzufügen</h1>

      <DashboardAddProductTabs />

      <hr />
      <div className="text-center">
        <LinkContainer to="/dashboard" exact>
          <Button type="primary" size="sm">Abbrechen und zurück</Button>
        </LinkContainer>
      </div>
    </Container>
  );
}

export default DashboardAddProduct;
