/** @jsx jsx */
import { jsx } from "@emotion/core";
import {Link} from 'react-router-dom';

import logo from './assets/logoNew.png';

function SiteHeaderLogo(props) {
  return (

    <div css={style => ({
      float: "left",
    })}>
      <Link to="/">
        <img src={logo} className="logo" alt="q_logo" height="50" width="auto" css={style => ({
            marginTop : "5px",
          })}/>
      </Link>
    </div> 
  );
}

export default SiteHeaderLogo;
