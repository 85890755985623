import React from 'react';




function AccountPaymentTab() {
  return (
    <div className="Home container" style={{margin: '11px'}}>

      <hr />
    </div>
  );
}

export default AccountPaymentTab;
