/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from 'react-router-dom';
// import theme from '../../app/theme'

// Bootstrap
import { Container, Card, Button } from "react-bootstrap";

// Components
import "./shop_styles.css"


function ProductTile(props) {

// className="product_tile"
  return (
    <Container className="product_tile_container" > {/* className="product_tile_container" */}
        <Link to={`/product/${props.id}`} style={{color: "black", textDecoration: 'none'}} key={props.id}>
        <Card className="product_tile" css={{'&:hover' : {
            backgroundColor: '#F5F5F5',
          }}}>
          <Card.Img variant="top" src={props.images} className="product_tile_image"/>
            <Card.Body>
              <div css={{
                display: "flex",
                flexDirection: "row",
              }}>
                <Card.Title style={{fontSize: "15px", letterSpacing: "1px"}}>{props.name}</Card.Title> 
                <div className="tile_btn_entfernung">
                  <p css={{marginTop: "6px", marginLeft: "20px"}}> > 3km</p>
                </div>
              </div>
              <Card.Text style={{fontSize: "13px", lineHeight: "16px"}}>{props.producer.name}</Card.Text>
              <Card.Text className="line-clamp" >{props.description}</Card.Text>
              <Card.Subtitle style={{fontSize: "15px"}}>{props.price} € pro  </Card.Subtitle>
              <Card.Text style={{fontSize: "12px"}}> {props.amount} Stk./ {props.price} €</Card.Text>
              <Button variant="primary" css={{color: "white", fontsize:"10", backgroundColor: "white", '&:hover' : {
                  color: '#FFFFFF',
                }}} style={{color: "black", borderRadius: '1px', borderColor: "black"}}>In den Warenkorb</Button>
            </Card.Body>
          </Card>
        </Link>
      </Container>
  );
}


export default ProductTile;
