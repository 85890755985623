import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_PRODUCTSBYPRODUCER } from '../../shared/ProductService';
import jwt from 'jsonwebtoken'
import { Container, Row, Col, CardDeck } from 'react-bootstrap';
import ProductTile from '../../shop/components/ProductTile';


function Dashboardpage(props) {
  const user = jwt.decode(window.localStorage.getItem('token'));
  const { loading, error, data } = useQuery(GET_PRODUCTSBYPRODUCER, {
    variables: { producerID: user.id }
  });

  if (loading) return <p>Loading..</p>;
  if (error) return <p>{error.message}</p>;
  if (data) { console.log("Data: " + data) };

  if (data) {
    return (
      <Container>
        {(data.length === 0) ? (
          <Fallback />
        ) : (
            <Row>
              <Col sm={12} md={12} lg={9}>
                <CardDeck>
                  {data.getProductsByProducer.map(({ _id, name, images, producerID, offerTypes, rating }) => (
                    <ProductTile
                      key={_id}
                      id={_id}
                      images={images.map(({ name }) => (name))}
                      producer={user.id}
                      name={name}
                      price={offerTypes.map(({ price }) => (price))}
                      amount={offerTypes.map(({ amount }) => (amount))}
                      unit={offerTypes.map(({ unit }) => (unit))}
                      rating={rating}
                    />
                  ))}
                </CardDeck>
              </Col>
            </Row>
          )}
      </Container>
    );
  } else {
    return <p>Keine Data vorhanden!</p>
  }
}

function Fallback() {
  return (
    <div className="fallback-section">
      <h3>Keine Produkte vorhanden</h3>
    </div>
  );
}

export default Dashboardpage;
