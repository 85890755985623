import { gql } from 'apollo-boost';

const GETORDERBYPRODUCER = gql`
query getOrderByProducer(
  $producerId: ID!){
  getOrdersByProducer(producerId: $producerId){
    products{
    	productName
      amount
    }
    pickupStation{
      locationId
    }
    date
    orderStatus{
      status
      date
    }
    bill{
      consumerAddress{
        street_name
        street_number
        city
        zip_code
        country
      }
      producerAddress{
        street_name
        street_number
        city
        zip_code
        country
      }
      billNumber
      issueDate
      dueDate
      total
      products{
        productId
        productName
        amount
        tax
        price
      }
      shippingPrice
      tax
      taxId
    }
}
  }
`

const GETORDERBYCONSUMER = gql`
query getOrdeByConsumer(
  $consumerId: ID!){
  getOrdersByConsumer(consumerId: $consumerId){
    _id
    producer
    groupId
    products{
      productId
    	productName
      amount
      tax
      price
    }
    total
    pickupStation{
      locationId
    }
    date
    orderStatus{
      status
      date
    }
    bill{
      consumerAddress{
        street_name
        street_number
        city
        zip_code
        country
      }
      producerAddress{
        street_name
        street_number
        city
        zip_code
        country
      }
      billNumber
      issueDate
      dueDate
      total
      products{
        productId
        productName
        amount
        tax
        price
      }
      shippingPrice
      tax
      taxId
    }
  }
}
`
// NOT TESTED YET!!!!
const CREATE_ORDER = gql`
mutation addOrder(
    $consumer: ID!, 
    $producer: ID!,
    $products: [ProductInput!]!, 
    $groupId: ID!, 
    $total: Float!,
    $pickupStation: PickupStationInput!,
    $date: DateTime!,
    $orderStatus: [OrderStatusInput!]!
    $paymentMethod: PaymentMethod!){
  createOrder(orderInput:{
    consumer: $consumer,
    producer: $producer,
    products: $products,
    groupId: $groupId,
    total: $total,
    pickupStation: $pickupStation
    date: $date,
    orderStatus: $orderStatus,
    paymentMethod: $paymentMethod
  }){
    _id
  }
}
`

// NOT TESTED YET!!!!
const CHANGE_ORDERSTATUS = gql`
mutation updateOrder(
  $orderId: ID!,
  $orderStatus: [OrderStatusInput!]!){
  changeOrderStatus(statusInput:{
    orderId: $orderId,
    orderStatus: $orderStatus
  }){
    _id
    orderStatus{
      status
      date
    }
  }
}
`


export {
  GETORDERBYPRODUCER,
  GETORDERBYCONSUMER,
  CREATE_ORDER,
  CHANGE_ORDERSTATUS
} 
