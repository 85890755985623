import React from 'react';
// import { useState } from 'react';
import { Link } from 'react-router-dom';

//Bootstrap
import { Container, Row, Col, Card, Tab, Tabs } from 'react-bootstrap';
import ProducerImg from '../../shared/assets/kuhimg.jpg';



function ProductBottom({ props }) {

  return (
    <div className="Productpage-container" style={{ margin: '11px' }}>

      <Container>
        <Row>
          <Col>
            <Tabs defaultActiveKey="details" >
              <Tab eventKey="details" title="Details">
                <p style={{ fontSize: '14px' }}>{`Beschreibung: ${props.longDescription}`}</p>
                <p style={{ fontSize: '14px' }}>{`Kategorie: ${props.category}`}</p>
                <p style={{ fontSize: '14px' }}>{`Allergien: ${props.allergen}`}</p>
                <p style={{ fontSize: '14px' }}>{`Farbe: ${props.color}`}</p>
                <p style={{ fontSize: '14px' }}>{`Verpackung: ${props.packaging}`}</p>
                <p style={{ fontSize: '14px' }}>{`Zustand: ${props.condition}`}</p>

              </Tab>
              <Tab eventKey="profile" title="Profil">
                <p style={{ fontSize: '14px' }}>{`Hier kommen später die öffentlichen Profildaten von ${props.producerID} hin`}</p>
              </Tab>
              <Tab eventKey="rating" title="Bewertungen" >
                <p style={{ fontSize: '14px' }}>Es sind noch keine Bewertungen vorhanden.</p>
              </Tab>
            </Tabs>
          </Col>

          <Col>
            <Card>
              <Card.Img variant="top" src={ProducerImg}/>
              <Card.Body>
                <Card.Title>{props.producerID}</Card.Title>
                <Card.Text>
                  <p style={{ fontSize: '14px' }}>
                    Beschreibung Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>
                </Card.Text>
                <Link className="btn btn-outline-secondary" to="/product/more-information">{`Mehr zu ${props.producerID}`}</Link>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
    </div>
  );
}

export default ProductBottom;
