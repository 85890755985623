/** @jsx jsx */
import { jsx } from "@emotion/core";
// import {Link} from 'react-router-dom';
import { Container, Row, Col, InputGroup, FormControl, Dropdown } from "react-bootstrap";

import './shop_styles.css';
// import icons_stift from '../../shared/assets/icons_stift.png';

function ZipCodeFilter(props) {

  return (
    <Container>
      <Row style={{width: "350px", margin: "5px"}}>
        <Col>
          <p css={{fontWeight: "bold", fontSize: "14px", marginTop: "8px"}}>Aktuelle PLZ:</p>
        </Col>
        <Col>
          <InputGroup size="sm">
            <FormControl
              placeholder="51643"
              aria-label="PLZ"
              aria-describedby="basic-addon2"
              style={{
                backgroundColor: "#D3D3D3",
                borderRadius: "0px",
              }}
            />
            {/* <InputGroup.Append>
              <Button variant="outline-secondary" size="sm" src={icons_stift} style={{borderRadius: "0px", backgroundColor: "#D3D3D3"}}/> 
            </InputGroup.Append> */}
          </InputGroup>
        </Col>
      </Row>

      <Row style={{width: "350px", margin: "5px"}}>
        <Col>
        <p css={{fontWeight: "bold", fontSize: "14px", marginTop: "8px"}}>Sortiert nach:</p>
        </Col>
        <Col>
         <Dropdown >
            <Dropdown.Toggle  size="sm" style={{width: "145px", backgroundColor: "#D3D3D3", color: "#696969", borderColor: "#FFFFFF" }} >
              Entfernung
            </Dropdown.Toggle>

            <Dropdown.Menu >
              <Dropdown.Item active href="#/action-1">Entfernung</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Preis</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Farbe</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </Container>
  );
}

export default ZipCodeFilter;
