/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';

function DashboardAddProductTabs5Cachet(props, { match }) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      props.handleNextStepClick(6)
    }
    setValidated(true);
  };

  return (
    <div>
      <h2>Füge optionale Details zu deinem Produkt hinzu</h2>
      <p className="lead">Alle Angaben in diesem Schritt sind optinal, du kannst auch alle Felder leer lassen und direkt auf "Weiter" klicken.</p>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Row>
              <Col xs={6}>
                <Form.Group controlId="product-brand">
                  <Form.Label>Marke</Form.Label>
                  <Form.Control
                    type="text"
                    name="brand"
                    onChange={props.handleChange}
                    value={props.values.brand}
                  />
                  <Form.Control.Feedback type="invalid">Bitte gib die Marke an.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={6}>
                <Form.Group controlId="product-color">
                  <Form.Label>Farbe</Form.Label>
                  <Form.Control
                    type="text"
                    name="color"
                    onChange={props.handleChange}
                    value={props.values.color}
                  />
                  <Form.Control.Feedback type="invalid">Bitte gib die Farbe an.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col xs={6}>
                <Form.Group controlId="product-condition">
                  <Form.Label>Zustand</Form.Label>
                  <Form.Control
                    as="select"
                    name="condition"
                    onChange={props.handleChange}
                    value={props.values.condition}
                  >
                    <option hidden label="-- Bitte auswählen --"></option>
                    <option value="MOIST">MOIST</option>
                    <option value="FROZEN">FROZEN</option>
                    <option value="COOLED">COOLED</option>
                    <option value="DRIED">DRIED</option>
                    <option value="RTP">RTP</option>
                    <option value="RIPE">RIPE</option>
                    <option value="RAW">RAW</option>
                    <option value="FERMENTED">FERMENTED</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Bitte wähle den Zustand aus.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={6}>
                <Form.Group controlId="product-packaging">
                  <Form.Label>Verpackung</Form.Label>
                  <Form.Control
                    as="select"
                    name="packaging"
                    onChange={props.handleChange}
                    value={props.values.packaging}
                  >
                    <option hidden label="-- Bitte auswählen --"></option>
                    <option value="PLASTIC">PLASTIC</option>
                    <option value="PAPER">PAPER</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Bitte wähle die Verpackung aus.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row>
              <Col xs={6}>
                <Form.Group controlId="product-additive">
                  <Form.Label>Zusatzstoffe</Form.Label>
                  <Form.Control
                    as="select" multiple
                    name="additive"
                    onChange={props.handleChange}
                    value={props.values.additive}
                  >
                    <option hidden label="-- Bitte auswählen --"></option>
                    <option value="DYE">DYE</option>
                    <option value="PRESERVATIVE">PRESERVATIVE</option>
                    <option value="ANTIOXIDANT">ANTIOXIDANT</option>
                    <option value="FLAVOUR_ENHANCER">FLAVOUR_ENHANCER</option>
                    <option value="SULFURED">SULFURED</option>
                    <option value="BLACKENED">BLACKENED</option>
                    <option value="WAXED">WAXED</option>
                    <option value="PHOSPHATE">PHOSPHATE</option>
                    <option value="SWEETENER">SWEETENER</option>
                    <option value="PHENYLALANINE">PHENYLALANINE</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Bitte wähle die Zusatzstoffe aus.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs={6}>
                <Form.Group controlId="product-foodSafety">
                  <Form.Label>Lebensmittelsicherheit</Form.Label>
                  <Form.Control
                    as="select" multiple
                    name="foodSafety"
                    onChange={props.handleChange}
                    value={props.values.foodSafety}
                  >
                    <option hidden label="-- Bitte auswählen --"></option>
                    <option value="ALCOHOL">ALCOHOL</option>
                    <option value="GELATIN">GELATIN</option>
                    <option value="PORK">PORK</option>
                    <option value="MEATLESS">MEATLESS</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Bitte wähle die Lebensmittelsicherheit aus.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
            <Col xs={6}>
                <Form.Group controlId="product-allergen">
                  <Form.Label>Allergen</Form.Label>
                  <Form.Control
                    as="select" multiple
                    name="allergen"
                    onChange={props.handleChange}
                    value={props.values.allergen}
                  >
                    <option hidden label="-- Bitte auswählen --"></option>
                    <option value="GLUTEN">GLUTEN</option>
                    <option value="CRUSTACEAN">CRUSTACEAN</option>
                    <option value="EGG">EGG</option>
                    <option value="FISH">FISH</option>
                    <option value="PEANUTS">PEANUTS</option>
                    <option value="SOYBEAN">SOYBEAN</option>
                    <option value="MILK">MILK</option>
                    <option value="LACTOSE">LACTOSE</option>
                    <option value="NUT">NUT</option>
                    <option value="CELERY">CELERY</option>
                    <option value="MUSTARD">MUSTARD</option>
                    <option value="SESAME_SEEDS">SESAME_SEEDS</option>
                    <option value="SULFUR_DIOXIDE">SULFUR_DIOXIDE</option>
                    <option value="SULFITE">SULFITE</option>
                    <option value="LUPIN">LUPIN</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">Bitte wähle die Allergene aus.</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        

        <div className="text-center">
          <Button size="lg" variant="primary" type="submit">Weiter</Button>
        </div>
      </Form>
    </div>
  );
}

export default DashboardAddProductTabs5Cachet;
