/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button, Form } from 'react-bootstrap';

function DashboardAddProductTabs3Images(props) {
  return (
    <div>
      <h2>Wähle ein Produktfoto aus</h2>

      <Form.Group controlId="product-image">
        <Form.Label>Eigenes Foto hochladen</Form.Label>
        <Form.Control
          required
          type="file"
          placeholder="Foto"
          // onChange="loadFile(event)"
        />
        <Form.Control.Feedback type="invalid">Bitte wähle ein Foto aus!</Form.Control.Feedback>
      </Form.Group>

      <h3>Aktuell ausgewähltes Foto:</h3>
      <img css={theme => ({
            maxWidth: "100%",
            marginBottom: theme.margins.componentDefault
          })} src={props.values.image} id="output" alt="Hochgeladenes Bild" className="rounded" />

      <div className="text-center">
        <Button size="lg" variant="primary" onClick={((e) => props.handleNextStepClick(4))} type="submit">Weiter</Button>
      </div>
    </div>
  );
}

export default DashboardAddProductTabs3Images;
