/** @jsx jsx */
import { jsx } from "@emotion/core";
import SiteHeaderNavigationButton from './SiteHeaderNavigationButton';
import dashboardIcon from './assets/dashboard.svg';
import accountIcon from './assets/account.svg';
import shoppingCartIcon from './assets/cart.svg';
import searchIcon from './assets/search.svg';


function SiteHeaderUserNavigation(props) {
  return (

    <div css={style => ({
      float: "right",
    })}>
        <SiteHeaderNavigationButton navIcon={searchIcon} pathTo={'/shop'} />
        <SiteHeaderNavigationButton navIcon={dashboardIcon} pathTo={'/dashboard'} />
        <SiteHeaderNavigationButton navIcon={shoppingCartIcon} pathTo={'/shoppingcart'} />
        <SiteHeaderNavigationButton navIcon={accountIcon} pathTo={'/account'} />
    </div>
  );
}

export default SiteHeaderUserNavigation;
