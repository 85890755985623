import React from 'react';
// import {gql} from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';

// Components
import ProductTile from '../../shop/components/ProductTile';
import IconBack from '../../shared/assets/icons_pfeil_links.png';
import jwt from 'jsonwebtoken';
import { Row, Col, CardDeck, Container, Button } from 'react-bootstrap';
import { GET_PRODUCTSBYPRODUCER } from '../../shared/ProductService';

// import DashboardHome from './pages/DashboardHome';
// import DashboardAddProduct from './pages/DashboardAddProduct';
// import AddProductCapture from './pages/AddProductCapture';


const user = jwt.decode(window.localStorage.getItem('token'));

function GetProducts() {
  
  const { loading, error, data } = useQuery(GET_PRODUCTSBYPRODUCER, {
    variables: { producerID: user.id }
  });

  if (loading) return <p>Loading..</p>;
  if (error) return <p>{error.message}</p>;

  if (data === 0) {
    return (
      <Container>
        {(data.length === 0) ? (
          <Fallback />
        ) : (
            <Row>
              <Col sm={12} md={12} lg={9}>
                <CardDeck>
                  {data.getProductsByProducer.map(({ _id, name, images, producerID, offerTypes, rating }) => (
                    <ProductTile
                      key={_id}
                      id={_id}
                      images={images.map(({ name }) => (name))}
                      producer={user.id}
                      name={name}
                      price={offerTypes.map(({ price }) => (price))}
                      amount={offerTypes.map(({ amount }) => (amount))}
                      unit={offerTypes.map(({ unit }) => (unit))}
                      rating={rating}
                    />
                  ))}
                </CardDeck>
              </Col>
            </Row>
          )}
      </Container>
    );
  } else {
    return <p style={{fontSize:"14px"}}>Keine Produkte vorhanden!</p>
  }
}

function DashboardBase(props) {
  // const { loading, error, data } = useQuery(GET_PRODUCTSBYPRODUCER, {
  //   variables: { producerID: user.id }
  // });
  // if (loading) return <p>Loading..</p>;
  // if (error) return <p>{error.message}</p>;


  if (window.localStorage.getItem('token')) {
    if (user.accountType === 'PRODUCER') {
      return (
        <Container>
          <Row style={{justifyContent: "left", alignContent: "center"}}>
            <img src={IconBack} alt="Zurück" width="20px" height="20px"  />
            <p style={{marginTop:"1px", marginLeft: "10px", fontSize: "12px", fontWeight: "bold"}}>Zurück</p>
          </Row>
          <Row style={{justifyContent: "center"}}>
            <Button variant="primary" active to="/" style={{margin: "5px", borderRadius: "2px"}}>Produkte</Button>{' '}
            <Button variant="primary" to="/" style={{margin: "5px", borderRadius: "2px"}}>Bestellungen</Button>{' '}
            <Button variant="primary" to="/" style={{margin: "5px", borderRadius: "2px"}}>Analytics</Button>{' '}
          </Row>
          <Row style={{justifyContent: "center", marginTop:"20px"}}>
            <GetProducts />
          </Row>      
        </Container>
      );
    } else {
      return (
        <Container>
          <h1>Kein Zugriff</h1>
          <p>Du bist im Webshop nicht als Erzeuger angemeldet und hast somit keinen Zugriff auf das Erzeuger-Dashboard.</p>
        </Container>
      )
    }
  } else {
    return (
      <Redirect to="/auth/login" />
    );
  }
}

function Fallback() {
  return (
    <div className="fallback-section">
      <h3>Noch keine Produkte vorhanden.</h3>
    </div>
  );
}

export default DashboardBase;
