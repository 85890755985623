/** @jsx jsx */
import { jsx } from "@emotion/core";
import {Link} from 'react-router-dom';


function SiteHeaderNavigationButton(props) {

  // function handleClick(path) {
  //   console.log({path})
  //   // Route zu Page einfügen // onClick={() => handleClick(props.pathTo)}
  //   // TODO: Ändern! 
  // }

    return (
        <Link to={props.pathTo}>
          <img src={props.navIcon} className="logo" alt="nav" style={{
            height: '20px',
            width: 'auto',
            marginRight: '15px',
            marginTop: '20px',
          }} />  
        </Link>
    );
  }

export default SiteHeaderNavigationButton;
