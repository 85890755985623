/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Container } from 'react-bootstrap';
import { Route, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import DashboardHome from './pages/DashboardHome';
import DashboardAddProduct from './pages/DashboardAddProduct';
import AddProductCapture from './pages/AddProductCapture';

const user = jwt.decode(window.localStorage.getItem('token'));

function Auth({ match }) {
  if (window.localStorage.getItem('token')) {
    if (user.accountType === 'PRODUCER') {
      return (
        <Container>
          <Route exact path={`${match.path}`} component={DashboardHome} />
          <Route path={`${match.path}/add-product`} component={DashboardAddProduct} />
          <Route path={`${match.path}/add-product/capture`} component={AddProductCapture} />
        </Container>
      );
    } else {
      return (
        <Container>
          <h1>Kein Zugriff</h1>
          <p>Du bist im Webshop nicht als Erzeuger angemeldet und hast somit keinen Zugriff auf das Erzeuger-Dashboard.</p>
        </Container>
      )
    }
  } else {
    return (
      <Redirect to="/auth/login" />
    );
  }
}

export default Auth;
