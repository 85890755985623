/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from 'react';
import {gql} from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import ClipLoader from 'react-spinners/ClipLoader';

//Table Components
// import 'node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ProductTile from '../components/ProductTile';

import { Container, Row, Col, CardDeck } from 'react-bootstrap';

const GET_FILTEREDPRODUCTS = gql`
  query getProductsByName($name: String!){
    getProductsByName(name: $name) {
      _id
      producerID
      name
      description
      images {
        name
        resolutions
      }
      offerTypes{
        price
        amount
        unit
      }
    }
  }
`;

// function imageFormatter(cell, row){
//   return (<img style={{width:33}} src={cell} alt="" />);
// }

function FilteredShop(props) {
  const [searchVal, setVal] = useState();

  useEffect(() => {
    setVal(props.searchVal);
  }, [props.searchVal])

  const params = {
    name: String(searchVal)
  }

  const {loading, error, data} = useQuery(GET_FILTEREDPRODUCTS, {
    variables: params,
  });
  if (loading) return <div className="text-center"><ClipLoader size={150} color={"#00A347"} loading={true}></ClipLoader></div>;
  if (error) return <p>{error.message}</p>;
  return (
    <div>
      <Container>
        {(data.getProductsByName.length === 0) ? (
          <h2 className="text-center">Keine Ergebnisse.</h2>
        ) : (
          <Row>
            {/* <Col className="hidden" sm={3} md={3} lg={3}>
              
            </Col> */}
            <Col sm={12} md={12} lg={12}>
            <CardDeck>
            {data.getProductsByName.map(({_id, name, images, producerID, offerTypes, rating}) => (
              <ProductTile
                key={_id} 
                id={_id}
                images={images.map(({name}) => (name))}
                producer={producerID}
                name={name}
                price={offerTypes.map(({price}) => (price))}
                amount={offerTypes.map(({amount}) => (amount))}
                unit={offerTypes.map(({unit}) => (unit))}
                rating={rating}
                />
            ))}
            </CardDeck>
            </Col>
          </Row>
        )}
      </Container>
      
      {/* <BootstrapTable data={data.getProductsByName} >
        <TableHeaderColumn dataField="image" dataFormat={imageFormatter}>Product Image</TableHeaderColumn>
        <TableHeaderColumn dataField="name" isKey={true} dataSort={true}>Product Name</TableHeaderColumn>
        <TableHeaderColumn dataField="description" dataSort={true}>Product Description</TableHeaderColumn>
        <TableHeaderColumn dataField="offerTypes.price" dataSort={true}>Price</TableHeaderColumn> 
      </BootstrapTable> */}
    </div>
  );
}

export default FilteredShop;
