import React, { useState, useEffect } from 'react';
import './productTemplate.css';
import { gql } from 'apollo-boost';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import jwt from 'jsonwebtoken'
import { Link } from 'react-router-dom';

const GET_ALLBASISTEMPLATES = gql`
  query getAllBasisTemplates{
    getAllBasisTemplates{
      _id
      title
      description
      longDescription
      img
      category
      images
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const GET_ALLTEMPLATESBYPRODUCER = gql`
  query getTemplatesByProducer ($producerId: String!){
    getTemplatesByProducer(producerId: $producerId){
      _id
      producerId
      title
      description
      longDescription
      img
      offerTypes{
        totalAmount
        packagingAmount
        price
        templateUnit
      }
      category
      images
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;
const GET_BASISTEMPLATESBYTITLE = gql`
  query getBasisTemplatesByTitle ($title: String!){
    getBasisTemplatesByTitle(title: $title){
      _id
      title
      description
      longDescription
      img
      offerTypes{
        totalAmount
        packagingAmount
        price
        templateUnit
      }
      category
      images
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;
const GET_SPECIFICTEMPLATESBYTITLE = gql`
  query getSpecificTemplatesByTitle ($title: String!, $producerId: ID!){
    getSpecificTemplatesByTitle(title: $title, producerId: $producerId){
      _id
      producerId
      title
      description
      longDescription
      img
      offerTypes{
        totalAmount
        packagingAmount
        price
        templateUnit
      }
      category
      images
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;

const DELETE_PRODUCERTEMPLATEBYID = gql`
  mutation DeleteSpecificTemplate($_id: ID!, $producerId: String!){
    deleteSpecificTemplate(_id: $_id, producerId: $producerId){
      _id
      title
      description
      longDescription
      img
      category
      allergen
      additive
      foodSafety
      color
      packaging
      condition
    }
  }
`;
const dataFromAllTemplates = {}
function TemplatesWrapper(props) {

  const user = jwt.decode(window.localStorage.getItem('token'))

  const [data, setData] = useState([]);
  const [templateDeleteStatus, setTemplateDeleteStatus] = useState(false)
  const [basisStatus, setBasisStatus] = useState(false);
  const [ownStatus, setOwnStatus] = useState(false);
  const [allStatus, setAllStatus] = useState(false);
  const allBasisTemplates = useQuery(GET_ALLBASISTEMPLATES);
  const allTemplatesByProducer = useQuery(GET_ALLTEMPLATESBYPRODUCER, { variables: { producerId: user.id } });
  const [basisTemplatesByTitle] = useLazyQuery(GET_BASISTEMPLATESBYTITLE, {
    onCompleted: data => {
      if (dataFromAllTemplates.basis === undefined) {
        dataFromAllTemplates.basis = data.getBasisTemplatesByTitle
        //console.log(dataFromAllTemplates)
      }
      if (dataFromAllTemplates.specific !== undefined) {
        let concatedData = dataFromAllTemplates.basis.concat(dataFromAllTemplates.specific)
        //console.log(dataFromAllTemplates)
        setData(concatedData)
      }
    }
  });

  const [specificTemplatesbyTitle, { someValue }] = useLazyQuery(GET_SPECIFICTEMPLATESBYTITLE, {
    onCompleted: data => {
      if (dataFromAllTemplates.specific === undefined) {
        dataFromAllTemplates.specific = data.getSpecificTemplatesByTitle
        //console.log(dataFromAllTemplates)
      }
      if (dataFromAllTemplates.basis !== undefined) {
        let concatedData = dataFromAllTemplates.specific.concat(dataFromAllTemplates.basis)
        //console.log(dataFromAllTemplates)
        setData(concatedData)
      }
    }
  })
  const skip = someValue !== undefined
  // const [basisTemplatesByTitle, { basisCalled, basisLoading, basisData, basisError }] = useLazyQuery(GET_BASISTEMPLATESBYTITLE);
  // const [specificTemplatesbyTitle, { specificCalled, specificLoading, specificData, specificError }] = useLazyQuery(GET_SPECIFICTEMPLATESBYTITLE);
  const [deleteSpecificTemplate] = useMutation(DELETE_PRODUCERTEMPLATEBYID);


  const handleBasisTemplates = (event) => {
    setBasisStatus(true)
    setOwnStatus(false)

    if (allBasisTemplates.error === undefined) {
      setData(allBasisTemplates.data.getAllBasisTemplates)
    } else {
      setData([])
      console.error('Cant get data');
    }
  }

  const handleOwnTemplates = (event) => {
    setBasisStatus(false)
    setOwnStatus(true)

    if (allTemplatesByProducer.error === undefined) {
      setData(allTemplatesByProducer.data.getTemplatesByProducer)
    } else {
      setData([])
      console.error('Cant get data');
    }
  }

  const handleAllTemplates = async (event) => {
    setBasisStatus(false)
    setOwnStatus(false)
    setAllStatus(true)

    let title = window.localStorage.getItem('detectedProduct')

    specificTemplatesbyTitle({ variables: { title: title, producerId: user.id } })
    basisTemplatesByTitle({ variables: { title: title }, skip })

    // return (
    //   <AddProductCapture></AddProductCapture>
    // )
  }

  // const getAllTemplates = async () => {
  //   setBasisStatus(false)
  //   setOwnStatus(false)
  //   setAllStatus(true)

  //   basisTemplatesByTitle({ variables: { title: window.localStorage.getItem('detectedProduct') } })
  //   specificTemplatesbyTitle({ variables: { title: window.localStorage.getItem('detectedProduct'), producerId: user.id } })

  //   console.log(basisData)
  //   console.log(specificData)
  //   // Wait for lazy query
  //   if (basisCalled && basisLoading) return (<p>lädt...</p>)

  //   if (basisData && specificData) {
  //     console.log(basisData)
  //   }
  // }

  if (window.localStorage.getItem('detectedProduct')) {
    //getAllTemplates()
  }

  const handleDeleteTemplate = (event, id, title) => {
    console.log(id)
    setTemplateDeleteStatus(true)
    // deleteSpecificTemplate({ variables: {_id: id}});
    // setTemplateDeleteStatus(false)
  }

  const handleDeleteAbort = (event) => {
    setTemplateDeleteStatus(false)
  }

  const handleDeleteQuery = (event, id) => {
    deleteSpecificTemplate({ variables: { id: id, producerId: user.id } });
    setTemplateDeleteStatus(false)
  }

  useEffect(() => { }, []);

  return (
    <>
      {(templateDeleteStatus === true) ? (
        <div>
          <div className="product-details-overlay"></div>
          <div className="delete-query-section">
            <div className="delete-content-section">
              <div className="description">
                Möchtest du die Vorlage wirklich löschen?
              </div>
              <div className="button-section">
                <button className="abort-button button" onClick={(e) => handleDeleteAbort(e, props.id)}>
                  Abbrechen
                </button>
                <button className="delete-button button" onClick={(e) => handleDeleteQuery(e)}>
                  Löschen
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
          null
        )}
      <div className="template-selection-wrapper">
        <BasisTemplates handleBasisTemplates={handleBasisTemplates} status={basisStatus} />
        <OwnTemplates handleOwnTemplates={handleOwnTemplates} status={ownStatus} />
        <AllTemplates handleAllTemplates={handleAllTemplates} status={allStatus} />
      </div>

      {(basisStatus && data.length === 0) || (ownStatus && data.length === 0) || (allStatus && data.length === 0) ? (
        <Fallback />
      ) : (
          <>
            {basisStatus || ownStatus || allStatus ? (
              <>
                <Searchbar basisStatus={basisStatus} />
                <div id="product-templates">
                  {data.map(({ _id, producerId, description, longDescription, title, img, offerTypes, category, allergen, additive, foodSafety, color, packaging, condition }) => (
                    <ProductTemplates
                      handleClick={props.handleClick}
                      handleProductDetails={props.handleProductDetails}
                      handleDeleteTemplate={handleDeleteTemplate}
                      key={title}
                      id={_id}
                      producerId={producerId}
                      title={title}
                      description={description}
                      longDescription={longDescription}
                      img={img}
                      offerTypes={offerTypes}
                      category={category}
                      allergen={allergen}
                      additive={additive}
                      foodSafety={foodSafety}
                      color={color}
                      packaging={packaging}
                      condition={condition}
                    />
                  ))}
                </div>
              </>
            ) : (null)}
          </>
        )
      }
    </>
  );
}


function BasisTemplates(props) {
  return (
    <div
      className="template-category"
      style={{
        boxShadow: props.status ? "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgb(244, 244, 244" : null,
        border: props.status ? "border: 2px solid #85bf5a" : "border: 1px solid #f5f5f5"
      }}>
      <h1
        style={{ color: props.status ? "green" : null }}>
        Basis Vorlagen
        </h1>
      <p>Wähle ein Produkt aus den jeweiligen Basis Vorlagen aus</p>
      <button
        disabled={props.status}
        style={{ color: props.status ? "grey" : null, borderBottom: props.status ? "1px solid grey" : null }}
        onClick={((e) => props.handleBasisTemplates(e))}>
        Verwenden
        </button>
    </div>
  );
}


function OwnTemplates(props) {
  return (
    <div
      className="template-category"
      style={{
        boxShadow: props.status ? "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgb(244, 244, 244" : null,
        border: props.status ? "border: 2px solid #85bf5a" : "border: 1px solid #f5f5f5"
      }}>
      <h1
        style={{ color: props.status ? "green" : null }}>
        Meine Produkt Vorlagen
      </h1>
      <p>Wähle ein Produkt aus, dass du bereits als Vorlage gespeichert hast</p>
      <button
        style={{ color: props.status ? "grey" : null, borderBottom: props.status ? "1px solid grey" : null }}
        disabled={props.status}
        onClick={((e) => props.handleOwnTemplates(e))}>
        Verwenden
      </button>
    </div>
  );
}

function AllTemplates(props) {
  if (!window.localStorage.getItem('detectedProduct')) {
    return (
      <div
        className="template-category"
        style={{
          boxShadow: props.status ? "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgb(244, 244, 244" : null,
          border: props.status ? "border: 2px solid #85bf5a" : "border: 1px solid #f5f5f5"
        }}>
        <h1
          style={{ color: props.status ? "green" : null }}>
          Produkt-Vorlage via Bild
      </h1>
        <p>Lass dir mögliche Produkt-Vorlagen anhand eines Bildes anzeigen.</p>
        {/* {<button type="primary" size="sm">Verwenden</button>} */}
        <Link to={`add-product/capture`}>
          <button
            style={{ color: props.status ? "grey" : null, borderBottom: props.status ? "1px solid grey" : null }}
            disabled={props.status}
          //onClick={((e) => props.getAllTemplates())}
          >
            Verwenden
      </button>
        </Link>
        {/* <Link className="btn btn-primary btn-block" to={`dashboard/add-product/capture`}>Verwenden</Link> */}
      </div>
    );
  } else {
    return (
      <div
        className="template-category"
        style={{
          boxShadow: props.status ? "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgb(244, 244, 244" : null,
          border: props.status ? "border: 2px solid #85bf5a" : "border: 1px solid #f5f5f5"
        }}>
        <h1
          style={{ color: props.status ? "green" : null }}>
          Folgendes Produkt wurde erkannt:
          <p>{window.localStorage.getItem('detectedProduct')}</p>
        </h1>
        <button
          style={{ color: props.status ? "grey" : null, borderBottom: props.status ? "1px solid grey" : null }}
          disabled={props.status}
          onClick={((e) => props.handleAllTemplates(e))}>
          Anzeigen
        </button>
      </div>
    );
  }
}


function ProductTemplates(props) {
  const templateProps = {
    title: props.title,
    description: props.description,
    longDescription: props.longDescription,
    img: props.img,
    offerTypes: props.offerTypes,
    category: props.category,
    images: props.images,
    allergen: props.allergen,
    additive: props.additive,
    foodSafety: props.foodSafety,
    color: props.color,
    packaging: props.packaging,
    condition: props.condition
  }
  if (props.producerId) {
    return (
      <div className="product-template-wrapper">

        <a href="test" className="search-link">
          {props.title}
        </a>
        <figure className="product-template">
          <h2 align="center">Deine Produkt Vorlagen</h2>
          <div className="card-img-wrapper">
            <img src={props.img} alt="template-pic" />
          </div>
          <figcaption>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
            <button
              className="product-detail-button"
              id="product-detail-button"
              onClick={((e) => props.handleProductDetails(e, templateProps))}
            >
              Detailierte Schnellansicht
          </button>
            <button
              className="product-usage-button"
              onClick={((e) => props.handleClick(e, templateProps))}
            >
              Verwenden
          </button>

            {(props.producerId !== undefined) ? (
              <div className="product-delete-button-section">
                <div className="product-delete-button"
                  onClick={(e) => props.handleDeleteTemplate(e, props.id, props.title)}
                >
                  Löschen
              </div>
              </div>
            ) : (
                null
              )}

          </figcaption>
        </figure>
      </div>
    )
  } else {
    return (
      <div className="product-template-wrapper">

        <a href="test" className="search-link">
          {props.title}
        </a>
        <figure className="product-template">
          <h2 align="center">Basis Vorlagen</h2>
          <div className="card-img-wrapper">
            <img src={props.img} alt="template-pic" />
          </div>
          <figcaption>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
            <button
              className="product-detail-button"
              id="product-detail-button"
              onClick={((e) => props.handleProductDetails(e, templateProps))}
            >
              Detailierte Schnellansicht
          </button>
            <button
              className="product-usage-button"
              onClick={((e) => props.handleClick(e, templateProps))}
            >
              Verwenden
          </button>

            {(props.producerId !== undefined) ? (
              <div className="product-delete-button-section">
                <div className="product-delete-button"
                  onClick={(e) => props.handleDeleteTemplate(e, props.id, props.title)}
                >
                  Löschen
              </div>
              </div>
            ) : (
                null
              )}

          </figcaption>
        </figure>
      </div>
    )
  }
}


function Fallback() {
  return (
    <div className="fallback-section">
      <h3>Keine Templates vorhanden</h3>
    </div>
  );
}


function Searchbar() {
  const handleKeyUp = () => {
    var input = document.getElementById('template-searchbar');
    var productTemplateElements = document.getElementsByClassName('product-template-wrapper');

    for (var i = 0; i < productTemplateElements.length; i++) {
      var a = productTemplateElements[i].getElementsByTagName('a')[0];
      if (a.innerHTML.toUpperCase().indexOf(input.value.toUpperCase()) > -1) {
        productTemplateElements[i].style.display = "";
      } else {
        productTemplateElements[i].style.display = 'none';
      }
    }
  }

  return (
    <div className="search-bar">
      <input
        type="text"
        name="search"
        autoComplete="on"
        id="template-searchbar"
        placeholder="Suchen"
        onKeyUp={handleKeyUp}
      />
    </div>
  );
}


export default TemplatesWrapper;
