import React from 'react';



function AccountSecurityTab() {
  return (
    <div className="Home container" style={{margin: '11px'}}>

      <hr />
    </div>
  );
}

export default AccountSecurityTab;
