/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Route } from 'react-router-dom';

import Profilepage from './pages/ProfilePage';

function Profile({ match }) {
  return (
    <div>
      <Route exact path={`${match.path}`} component={Profilepage} />
    </div>
  );
}

export default Profile;
