/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from 'react';

import { Button, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function DashboardAddProductTabs2Description(props) {
  const [addDetail, setAddDetail] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      props.handleNextStepClick(3)
    }
    setValidated(true);
  };

  return (
    <div>
      <h2>Gib die ersten Daten zu dem Produkt ein</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="product-name">
              <Form.Label>Produktname</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Produktname"
                name="name"
                onChange={props.handleChange}
                value={props.values.name} size="45"
              />
              <Form.Control.Feedback type="invalid">Bitte gib einen Titel an!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="product-description">
              <Form.Label>Kurzbeschreibung</Form.Label>
              <Form.Control
                required
                as="textarea"
                cols="40" rows="4"
                placeholder="Gebe hier die Kurzbeschreibung ein."
                name="description"
                onChange={props.handleChange}
                value={props.values.description}
              />
              <Form.Control.Feedback type="invalid">Bitte gib eine Kurzbeschreibung an!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="product-category">
              <Form.Label>Kategorie</Form.Label>
              <Form.Control
                required
                as="select"
                name="category"
                onChange={props.handleChange}
                value={props.values.category}
              >
                <option hidden label="-- Bitte auswählen --"></option>
                <option value="CEREAL">CEREAL</option>
                <option value="CEREAL_PRODUCTS">CEREAL_PRODUCTS</option>
                <option value="POTATO">POTATO</option>
                <option value="POTATO_PRODUCTS">POTATO_PRODUCTS</option>
                <option value="FRUIT">FRUIT</option>
                <option value="FRUIT_PRODUCTS">FRUIT_PRODUCTS</option>
                <option value="DRIED_FRUIT">DRIED_FRUIT</option>
                <option value="VEGETABLES">VEGETABLES</option>
                <option value="PULSES">PULSES</option>
                <option value="NUTS">NUTS</option>
                <option value="SEEDS">SEEDS</option>
                <option value="MEAT">MEAT</option>
                <option value="MEAT_PRODUCTS">MEAT_PRODUCTS</option>
                <option value="FISH">FISH</option>
                <option value="FISH_PRODUCTS">FISH_PRODUCTS</option>
                <option value="MILK">MILK</option>
                <option value="DAIRY_PRODUCTS">DAIRY_PRODUCTS</option>
                <option value="EGG">EGG</option>
                <option value="EGG_DISHES">EGG_DISHES</option>
                <option value="OILS">OILS</option>
                <option value="FATS">FATS</option>
                <option value="CONFECTIONERY">CONFECTIONERY</option>
                <option value="SUGAR">SUGAR</option>
                <option value="PRESERVES">PRESERVES</option>
                <option value="READY_MEALS">READY_MEALS</option>
                <option value="SAUCES">SAUCES</option>
                <option value="SEASONINGS">SEASONINGS</option>
                <option value="SPICES">SPICES</option>
                <option value="HERBS">HERBS</option>
                <option value="NON_ALCOHOLIC">NON_ALCOHOLIC</option>
                <option value="ALCOHOLIC">ALCOHOLIC</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">Bitte wähle die Kategorie aus.</Form.Control.Feedback>
            </Form.Group>
            <Button size="sm" variant="primary" onClick={() => setAddDetail(true)} className={ `${addDetail ? "d-none" : "d-block"}`} type="button"><FontAwesomeIcon icon={faPlus} /> Detaillierte Beschreibung (optional)</Button>
          </Col>
          <Col className={ `${addDetail ? "d-block" : "d-none"}`}>
            <Form.Group controlId="product-description-long">
              <Form.Label> Detaillierte Beschreibung (optional)</Form.Label>
              <Form.Control
                as="textarea"
                cols="40" rows="11"
                placeholder="Gebe hier eine optionale detaillierte Beschreibung ein."
                name="descriptionLong"
                onChange={props.handleChange}
                value={props.values.descriptionLong}
              />
              <Form.Control.Feedback type="invalid">Bitte gib eine detaillierte Beschreibung an!</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="text-center">
          <Button size="lg" variant="primary" type="submit">Weiter</Button>
        </div>
      </Form>
    </div>
  );
}

export default DashboardAddProductTabs2Description;
