/** @jsx jsx */
import { jsx } from "@emotion/core";
import {gql} from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

// Components
import ShopProducts from '../components/ShopProducts';
import '../components/shop_styles.css';

const GET_ALLPRODUCTS = gql`
  query getAllProducts{
    getAllProducts{
      _id
      producerID
      name
      description
      images {
        name
        resolutions
      }
      offerTypes {
        price
        amount
        unit
      }
      rating
    }
  }
`; 

function Shoppage( {match} ) {
  const {loading, error, data} = useQuery(GET_ALLPRODUCTS);

  return (
    <ShopProducts categoryPage={false} loading={loading} error={error} data={data} />
  )
}

export default Shoppage;
