import React from 'react';

//Bootstrap
import { Alert, Button, Container, Row, Col, ListGroup, Card } from 'react-bootstrap';
import ShoppingCartProductTile from '../components/ShoppingCartProductTile';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_SHOPPINGCART, EMPTY_SHOPPINGCART } from '../../shared/ShoppingCartService';
import { CREATE_ORDER } from '../../shared/OrderService'
import jwt from 'jsonwebtoken'

function ShoppingCartPage() {

  const user = jwt.decode(window.localStorage.getItem('token'))

  const shoppingCartElements = []
  let productTotal = 0

  function createShoppingCartProductTiles(productData) {
    productData.products.forEach(element => {
      element.userId = user.id
      shoppingCartElements.push(<ShoppingCartProductTile key={element.productId} props={{ element }} />)
      productTotal += element.pricePerUnit * element.amount
    })
    return shoppingCartElements
  }

  const [createOrder] = useMutation(CREATE_ORDER)

  const [emptyShoppingCart] = useMutation(EMPTY_SHOPPINGCART)

  const { called, data, loading, error } = useQuery(GET_SHOPPINGCART, {
    variables: { consumerId: user.id }
  });

  // Wait for lazy query
  if (called && loading) return <p> Warenkorbinhalte werden geladen... </p>

  // Show error message if lazy query fails
  if (error) return <p>{error.message}</p>

  // orderProducts array should be filtered by producerId in order to create multiple orders, if there are multiple producerIds
  // only one order is created per checkout, as the producerId is set once, using the first product in the shopping cart
  let producerId = ""
  if (data.getShoppingCartByConsumer.products.length) producerId = data.getShoppingCartByConsumer.products[0].producerId

  let orderProducts = JSON.parse(JSON.stringify(data.getShoppingCartByConsumer.products))
  orderProducts.forEach((element, index) => {
    orderProducts[index].price = element.pricePerUnit * element.amount
    delete element.__typename
    delete element.producerId
    delete element.pricePerUnit
    delete element.image
    delete element.userId
  })

  function submitOrder() {

    createOrder({
      variables: {
        consumer: user.id,
        producer: producerId,
        products: orderProducts,
        groupId: "test",
        total: productTotal,
        pickupStation: {
          locationId: "overath01",
          pickupDeadline: "morgen",
          name: "OverathCenter",
          pickupType: "PRODUCER",
          address: {
            street_name: "Overstraße",
            street_number: "12A",
            city: "Overath",
            zip_code: 51234,
            country: "Deutschland"
          },
          openingHours: {
            is_closed: false,
            start: "9:00",
            end: "18:00"
          }
        },
        date: "now",
        orderStatus: [{
          status: "ORDERED",
          date: "now"
        }],
        paymentMethod: "PAYPAL"
      }
    })

    alert("Deine Bestellung wurde erfolgreich abgeschickt. Du erhältst eine Benachrichtigung per Mail.");

    emptyShoppingCart({
      variables: {
        consumerId: user.id
      }
    })
    window.location.assign('')
  }

  if (data.getShoppingCartByConsumer != null) {
    createShoppingCartProductTiles(data.getShoppingCartByConsumer)
    return (
      <Container>
        <Row>
          <h1>Warenkorb</h1>
        </Row>
        <Row>
          <p>Sie haben {data.getShoppingCartByConsumer.products.length} Artikel im Warenkorb.</p>
        </Row>
        <Row>
          <Col sm={12}>
            <Row>
              <ListGroup>
                {shoppingCartElements}
              </ListGroup>
            </Row>
          </Col>

        </Row>
        <hr />
        <Row>
          <Col sm={12} md={{ span: 4, offset: 8 }}>
            <Card className="text-center" style={{ marginRight: "1rem" }}>
              <Card.Body>
                <Card.Title>Summe ({data.getShoppingCartByConsumer.products.length} Artikel): </Card.Title>
                <Card.Subtitle>€ {parseFloat(productTotal).toFixed(2)}</Card.Subtitle>
                <Button onClick={submitOrder}>Bestellung abschicken</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return (
      <Container>
        <Alert key={1} variant={"danger"}>
          404 NOT FOUND!
      </Alert>
      </Container>
    )
  }


}

export default ShoppingCartPage;
