import React from 'react';
import { Form, Button, Col, Container, Alert } from 'react-bootstrap';
import { useForm } from '../../shared/hooks/useForm'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_ACCOUNT, MODIFY_ACCOUNT } from './AccountService';
import jwt from 'jsonwebtoken';

function AccountGeneralTab() {

  const user = jwt.decode(window.localStorage.getItem('token'))

  const { values, handleChange, handleSubmit } = useForm(() => {
    // Nicht schön!!! Wird noch iterriert... Make it work first, refactor later!
    // Auch eine Abfrage, ob etwas geändert wurde, wäre nützlich, 
    // zum beispiel Button erst klickbar machen wenn ein habndleChange ausggelöst wurde
    values.id = user.id
    values.name = document.getElementById('formName').getAttribute('value')
    values.surname = document.getElementById('formSurname').getAttribute('value')
    values.email = document.getElementById('formEmail').getAttribute('value')
    values.street_name = document.getElementById('formAddressStreetName').getAttribute('value')
    values.street_number = document.getElementById('formAddressStreetNumber').getAttribute('value')
    values.city = document.getElementById('formAddressCity').getAttribute('value')
    values.zip_code = parseInt(document.getElementById('formAddressZipCode').getAttribute('value'))
    values.country = document.getElementById('formAddressCountry').getAttribute('value')
    modifyAccount()
    alert('Änderung gespeichert!')
  })

  const { called, data, loading, error } = useQuery(GET_ACCOUNT, {
    variables: { id: user.id }
  });

  if (values.zip_code)
    values.zip_code = parseInt(values.zip_code)


  const [modifyAccount] = useMutation(MODIFY_ACCOUNT, {
    variables: values
  });

  // Wait for lazy query
  if (called && loading) return <p> LOOOAAAAAAAAADING... </p>

  // Show error message if lazy query fails
  if (error) return <p>{error.message}</p>

  // Store token if login is successful
  if (data.getAccount != null) {

    const { street_name, street_number, city, zip_code, country } = data.getAccount.address


    return (
      <div className="Home container" style={{ margin: '11px' }}>
        <Container>
          <Col md="10" className="mx-auto">

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" defaultValue={data.getAccount.name} onChange={handleChange} value={values.name} required />
              </Form.Group>
              <Form.Group controlId="formSurname">
                <Form.Label>Nachname</Form.Label>
                <Form.Control type="text" name="surname" defaultValue={data.getAccount.surname} onChange={handleChange} value={values.surname} required />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" defaultValue={data.getAccount.email} onChange={handleChange} value={values.email} required />
              </Form.Group>

              <Form.Label>Addresse</Form.Label>
              <Form.Group controlId="formAddressStreetName">
                <Form.Label style={{ fontSize: 16 }}>Straße</Form.Label>
                <Form.Control type="text" name="street_name" defaultValue={street_name} onChange={handleChange} value={values.street_name} required />
              </Form.Group>
              <Form.Group controlId="formAddressStreetNumber">
                <Form.Label style={{ fontSize: 16 }}>Nummer</Form.Label>
                <Form.Control type="text" name="street_number" defaultValue={street_number} onChange={handleChange} value={values.street_number} required />
              </Form.Group>
              <Form.Group controlId="formAddressCity">
                <Form.Label style={{ fontSize: 16 }}>Stadt</Form.Label>
                <Form.Control type="text" name="city" defaultValue={city} onChange={handleChange} value={values.city} required />
              </Form.Group>
              <Form.Group controlId="formAddressZipCode">
                <Form.Label style={{ fontSize: 16 }}>PLZ</Form.Label>
                <Form.Control type="number" name="zip_code" defaultValue={zip_code} onChange={handleChange} value={values.zip_code} required />
              </Form.Group>
              <Form.Group controlId="formAddressCountry">
                <Form.Label style={{ fontSize: 16 }}>Land</Form.Label>
                <Form.Control type="text" name="country" defaultValue={country} onChange={handleChange} value={values.country} required />
              </Form.Group>

              <Button variant="primary" type="submit">
                Änderung speichern
            </Button>
            </Form>
          </Col>
        </Container>
      </div>
    )
  } else {
    return (
      <Alert key={1} variant={"danger"}>
        404 NOT FOUND!
      </Alert>
    )
  }
}

export default AccountGeneralTab;
