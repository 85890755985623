/** @jsx jsx */
import { jsx } from "@emotion/core";

// Components
import ProductTile from '../components/ProductTile';
import '../components/shop_styles.css';

// Bootstrap
import { Col, CardDeck } from 'react-bootstrap';

function ShopProductsCardDeck( {data}) {
  return (
    <CardDeck >
      {data.map(({_id, name, description, images, producerID, offerTypes, rating}) => (
        <Col key={_id}>
          <ProductTile
            key={_id} 
            id={_id}
            description={description}
            images={images.map(({name}) => (name))}
            producer={producerID}
            name={name}
            price={offerTypes.map(({price}) => (price))}
            amount={offerTypes.map(({amount}) => (amount))}
            unit={offerTypes.map(({unit}) => (unit))}
            rating={rating}
          />
        </Col> 
      ))}
    </CardDeck>
  );
}

export default ShopProductsCardDeck;
