import React from 'react';
import { Col, Row, Tab, ListGroup } from 'react-bootstrap';

import AccountGeneralTab from '../components/AccountGeneralTab';
import AccountOrderTab from '../components/AccountOrderTab';
import AccountPaymentTab from '../components/AccountPaymentTab';
import AccountSecurityTab from '../components/AccountSecurityTab';
import AccountSettingTab from '../components/AccountSettingTab';

import '../components/accountStyles.css';

function AccountPage() {
  return (
    <div className="account_navigation_container">
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#accountlink" >
        <Row>
          <Col sm={4} md={4} lg={3}>
            <ListGroup>
            <ListGroup.Item disabled action href="#link0">
                Settings
              </ListGroup.Item>
              <ListGroup.Item action href="#accountlink">
                Account
              </ListGroup.Item>
              <ListGroup.Item action href="#orderslink">
                Orders
              </ListGroup.Item>
              <ListGroup.Item action href="#securitylink">
                Security
              </ListGroup.Item>
              <ListGroup.Item action href="#paymentlink">
                Payment
              </ListGroup.Item>
              <ListGroup.Item action href="#settingslink">
                General
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={8} md={8} lg={7}> 
            <Tab.Content >
              <Tab.Pane eventKey="#accountlink">
                <AccountGeneralTab />
              </Tab.Pane>
              <Tab.Pane eventKey="#orderslink">
                <AccountOrderTab />
              </Tab.Pane>
              <Tab.Pane eventKey="#securitylink">
                <AccountPaymentTab />
              </Tab.Pane>
              <Tab.Pane eventKey="#paymentlink">
                <AccountSecurityTab />
              </Tab.Pane>
              <Tab.Pane eventKey="#settingslink">
                <AccountSettingTab />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

    </div>
  );
}

export default AccountPage;
