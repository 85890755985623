/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Route, Redirect } from 'react-router-dom';

import Accountpage from './pages/AccountPage';

function Account({ match }) {
  if (window.localStorage.getItem('token')) {
    return (
      <div>
        <Route exact path={`${match.path}`} component={Accountpage} />
      </div>
    );
  } else {
    return (
      <div>
        <Redirect to="../auth/login" />
      </div>
    );
  }

}

export default Account;
