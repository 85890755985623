/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Route } from 'react-router-dom';

import Dashboardpage from '../components/DashboadProductList';

function DashboardHome({ match }) {
  return (
    <Container>
      <h1>Dashboard</h1>
      <Row>
        <Col sm={10}>
          <h2>Meine Produkte</h2>
          <Route exact path={`${match.path}`} component={Dashboardpage} />
        </Col>
        <Col sm={2}>
        <Link className="btn btn-primary btn-block" to={`${match.url}/add-product`}>Produkt hinzufügen</Link>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardHome;

