/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from 'react';
import { Button } from 'react-bootstrap';

import ProductTemplateSection from './ProductTemplateService/ProductTemplate';
import rightArrowIcon from '../../shared/assets/right-arrow.png'

function DashboardAddProductTabs1Search(props) {
  const [detailStatus, setDetailStatus] = useState(false);
  const [templateData, setTemplateData] = useState();

  const [templateTitle, setTitle] = useState();
  const [templateDescription, setDesciption] = useState();
  const [templateLongDescription, setLongDescription] = useState();
  const [templateImage, setImage] = useState();
  const [templateOfferTypes, setOfferTypes] = useState();
  const [templateCategory, setCategory] = useState();
  // const [templateImages, setImages] = useState();
  const [templateAllergen, setAllergen] = useState();
  const [templateAdditive, setAdditive] = useState();
  const [templateFoodSafety, setFoodSafety] = useState();
  const [templateColor, setColor] = useState();
  const [templatePackaging, setPackaging] = useState();
  const [templateCondition, setCondition] = useState();

  const handleProductDetails = (event, templateProps) => {
    setDetailStatus(true)
    document.body.style.overflow = "hidden"
    setTemplateData(templateProps)

    setTitle(templateProps.title)
    setDesciption(templateProps.description)
    setLongDescription(templateProps.longDescription)
    setImage(templateProps.img)
    setOfferTypes(templateProps.offerTypes)
    setCategory(templateProps.category)
    // setImages(templateProps.images)
    setAllergen(templateProps.allergen)
    setAdditive(templateProps.additive)
    setFoodSafety(templateProps.foodSafety)
    setColor(templateProps.color)
    setPackaging(templateProps.packaging)
    setCondition(templateProps.condition)
  }

  const handleDetailsClose = (event) => {
    setDetailStatus(false)
    document.body.style.overflow = "scroll"
  }

  return (
    <div>
      {(detailStatus)
        ? (<ProductDetails
            key={templateTitle}
            handleDetailsClose={handleDetailsClose}
            handleTemplateUsage={props.handleTemplateUsage}
            templateData={templateData}
            titleValue={templateTitle}
            descriptionValue={templateDescription}
            longDescriptionValue={templateLongDescription}
            imageValue={templateImage}
            offerTypesValue={templateOfferTypes}
            categoryValue={templateCategory}
            // imagesValue={templateImages}
            allergenValue={templateAllergen}
            additiveValue={templateAdditive}
            foodSafetyValue={templateFoodSafety}
            colorValue={templateColor}
            packagingValue={templatePackaging}
            conditionValue={templateCondition}
          />)
        : (null)
      }
      <h2>Suche dein Produkt</h2>
      <ProductTemplateSection handleClick={props.handleClick} handleProductDetails={handleProductDetails} />

      <div className="text-center">
        <Button size="lg" variant="primary" onClick={((e) => props.handleNextStepClick(2))} type="submit">Ohne Vorlage beginnen</Button>
      </div>
    </div>
  );
}

function ProductDetails(props){
  return (
    <div>
    <div className="product-details-overlay"  onClick={((e) => props.handleDetailsClose(e))}></div>
    <div className="product-details-sidebar">
      <div className="product-details-container">
        <div className="detail-close-section" onClick={((e) => props.handleDetailsClose(e))}>
          <span className="detail-close-text">Details schliessen</span>
          <img className="produkt-information-schliessen-icon" src={rightArrowIcon} alt="right-arrow-icon"/>
        </div>
        <div className="details-image-section">
          <img className="details-image" src={props.imageValue} alt={props.titleValue}/>
        </div>

        <div className="details-main-information">
          <h1 className="details-title">{props.titleValue}</h1>
          <p className="details-description">{props.descriptionValue}</p>

          <div className="details-images">
            <h2 className="details-undertitle">Detaillierte Beschreibung</h2>
            <div className="details-text">
              <p className="details-description">{props.longDescriptionValue}</p>
            </div>
          </div>

          <div className="details-images">
            <h2 className="details-undertitle">Bilder</h2>
            <div className="details-text">
              <span>-</span>
            </div>
          </div>
          <div className="details-price">
            <h2 className="details-undertitle">Preis</h2>
            <div className="details-text">
              {(props.offerTypesValue !== undefined) ? (
                <div>
                  <span className="detail-view-price">{props.offerTypesValue[0].price} € </span>
                  <span>/</span>
                  <span> {props.offerTypesValue[0].packagingAmount} {props.offerTypesValue[0].templateUnit}</span>
                </div>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
          <div className="details-totalAmount">
            <h2 className="details-undertitle">Anzubietende Gesamtmenge</h2>
            <div className="details-text">
            {(props.offerTypesValue !== undefined) ? (
              <div>
                <span> {props.offerTypesValue[0].totalAmount} {props.offerTypesValue[0].unit}</span>
              </div>
            ) : (
              <span>-</span>
            )}
            </div>
          </div>
          <div className="details-category">
            <h2 className="details-undertitle">Kategorie</h2>
            <div className="details-text">
              <span>{props.categoryValue}</span>
            </div>
          </div>
          <div className="details-allergene">
            <h2 className="details-undertitle">Allergene</h2>
            <div className="details-text">
              {props.allergenValue.map((allergene) => (
                <span>{allergene}, </span>
              ))}
            </div>
          </div>
          <div className="details-additives">
            <h2 className="details-undertitle">Zusatzstoffe</h2>
            <div className="details-text">
              {props.additiveValue.map((additive) => (
                <span>{additive}, </span>
              ))}
            </div>
          </div>
          <div className="details-foodSafety">
            <h2 className="details-undertitle">Lebensmittelsicherheit</h2>
            <div className="details-text">
              {props.foodSafetyValue.map((foodSafety) => (
                <span>{foodSafety}, </span>
              ))}
            </div>
          </div>
          <div className="details-color">
            <h2 className="details-undertitle">Farbe</h2>
            <div className="details-text">
              <span>{props.colorValue}</span>
            </div>
          </div>
          <div className="details-packaging">
            <h2 className="details-undertitle">Verpackung</h2>
            <div className="details-text">
              <span>{props.packagingValue}</span>
            </div>
          </div>
          <div className="details-condition">
            <h2 className="details-undertitle">Kondition</h2>
            <div className="details-text">
              <span>{props.conditionValue}</span>
            </div>
          </div>
        </div>
        <div className="detail-buttons">
          <button className="product-usage-button" onClick={((e) => props.handleTemplateUsage(e, props.templateData))}>Vorlage verwenden</button>
          <button className="product-publish-button">Produkt einstellen</button>
        </div>
      </div>
    </div>
    </div>
  )
}

export default DashboardAddProductTabs1Search;
