/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Route } from 'react-router-dom';

import Shoppage from './pages/Shoppage';
import FilteredShopPageByCategory from "./pages/FilteredShopPageByCategory";


function Shop({ match }) {
  return (
    <div>
      <Route exact path={`${match.path}`} component={Shoppage} />
      <Route exact path={`${match.path}/category/:categorySlug`} component={FilteredShopPageByCategory} />
    </div>
  );
}

export default Shop;
