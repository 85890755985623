/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from 'react';
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';

function DashboardAddProductTabs4Price(props, { match }) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      props.handleNextStepClick(5)
    }
    setValidated(true);
  };

  return (
    <div>
      <h2>Gib die Menge und den Preis an</h2>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <h3>Gesamtmenge</h3>

        <Row>
          <Col xs={6} md={3}>
            <Form.Group controlId="product-amount-total">
              <Form.Label>Menge</Form.Label>
              <Form.Control
                required
                type="number"
                min="1" step="1"
                name="amountTotal"
                onChange={props.handleChange}
                value={props.values.amountTotal}
              />
              <Form.Control.Feedback type="invalid">Bitte gib die Menge an.</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={6} md={3}>
            <Form.Group controlId="product-unit">
              <Form.Label>Einheit</Form.Label>
              <Form.Control
                required
                as="select"
                name="unit"
                onChange={props.handleChange}
                value={props.values.unit}
              >
                <option hidden label="-- Bitte auswählen --"></option>
                <option value="kg">Killogramm (kg)</option>
                <option value="g">Gramm (g)</option>
                <option value="l">Liter (l)</option>
                <option value="ml">Milliliter (ml)</option>
                <option value="Stk.">Stück (Stk.)</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">Bitte wähle die Einheit aus.</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-muted">Hinweis: Die gewählte Einheit wird auch für alle Mengen bei den Preisen verwendet.</p>
          </Col>
        </Row>

        <hr />

        <h3>Preise</h3>

        <Row>
          <Col xs={6} md={3}>
            <Form.Group controlId="product-amount">
              <Form.Label>Menge</Form.Label>
              <InputGroup>
              <Form.Control
                required
                type="number"
                min="1" step="1"
                name="amount"
                onChange={props.handleChange}
                value={props.values.amount}
              />
                <InputGroup.Append>
                  <InputGroup.Text>{props.values.unit}</InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">Bitte gib die Menge an.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col xs={6} md={3}>
            <Form.Group controlId="product-amount-price">
              <Form.Label>Preis</Form.Label>
              <InputGroup>
              <Form.Control
                required
                type="number"
                min="0.01" step="0.01"
                name="amountPrice"
                onChange={props.handleChange}
                value={props.values.amountPrice}
              />
                <InputGroup.Append>
                  <InputGroup.Text>€</InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid">Bitte gib den Preis an.</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        {/* <h4>Empfehlung(en) für häufig genutze Mengenpreise</h4>
          <table className="table table-bordered">
            <thead>
            <tr>
                <th scope="col">10 Kg</th>
                <th scope="col">6,99 €</th>
            </tr>
            </thead>
        </table> */}
        <div className="text-center">
          <Button size="lg" variant="primary" type="submit">Weiter</Button>
        </div>
      </Form>

    </div>
  );
}

export default DashboardAddProductTabs4Price;
