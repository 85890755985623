/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ThemeProvider } from 'emotion-theming';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useState } from 'react';


// global components
import SiteHeader from '../shared/SiteHeader';
// import SiteNav from '../shared/SiteNav';
import SiteFooter from '../shared/SiteFooter';

// components
import Home from './Home';
import Auth from '../auth/Auth';
import Dashboard from '../dashboard/Dashboard';
import Shop from '../shop/Shop';
import Product from '../product/Product';
import Account from '../account/Account';
import Profile from '../profile/Profile';
import ShoppingCart from '../shoppingCart/ShoppingCart';
import FilteredShop from "../shop/pages/FilteredShopPage";

// emotion theme
import theme from './theme';

// Apollo Client
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';


var clientUri = "";

if (process.env.NODE_ENV !== 'production') {
  clientUri = "https://mivs01.gm.fh-koeln.de/graphql"
} else {
  clientUri = "https://mivs01.gm.fh-koeln.de/graphql"
}

const client = new ApolloClient({
  uri: clientUri,
  request: (operation) => {
    const token = localStorage.getItem('token')
    operation.setContext({
      headers: {
        authorization: token ? `${token}` : ""
      }
    })
  }
});



function App() {
  const [searchVal, setSearchValue] = useState();
  const [isFiltered, setFiltered] = useState();

  function handleSearch(searchValue, isFiltered) {
    console.log("App: " + searchValue);
    setSearchValue(searchValue);
    setFiltered(isFiltered);
  }

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <Router className="content-Router">
            <SiteHeader consumerheader={false} handleSearch={handleSearch.bind(this)} />
            <div css={{
              flex: '1 0 auto',
              marginTop: theme.margins.componentDefault,
            }}>
              <Route exact path="/" >
                <Home searchVal={searchVal} isFiltered={isFiltered} />
              </Route>
              <Route path="/filtered" component={FilteredShop} />
              <Route path="/auth" component={Auth} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/shop" component={Shop} />
              <Route path="/product" component={Product} />
              <Route path="/account" component={Account} />
              <Route path="/profile" component={Profile} />
              <Route path="/shoppingcart" component={ShoppingCart} />
            </div>
            <SiteFooter />
          </Router>
        </ApolloProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
