import React from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const DETECTPRODUCT = gql`
  mutation detectProduct($encodedMedia: String!){
  detectProduct(encodedMedia:$encodedMedia){
    name
    message
  }
}
`;

function AddProductCapture(props) {
  async function handleTakePhoto(dataUri) {
    let dataUriArray = dataUri.split(",")
    //console.log(dataUriArray[1]);
    detectProduct({ variables: { encodedMedia: dataUriArray[1] } });

  }

  //const detectProduct = useMutation(DETECTPRODUCT, { variables: { encodedMedia: user.id } });
  const [detectProduct, { called, data, loading, error }] = useMutation(DETECTPRODUCT);

  // Wait for lazy query
  if (called && loading) return <p>lädt...</p>

  // Show error message if lazy query fails
  if (error) return <p>{error.message} </p>

  if (data) {
    console.log(data.detectProduct.name)
    window.localStorage.setItem('detectedProduct', data.detectProduct.name)

  }
  if (!data) {
    return (
      <Camera
        onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
        idealResolution={{ width: 200, height: 200 }}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
      //isFullscreen={true}
      />
    );
  } else {
    return (
      <div>
        {window.location.assign('/dashboard/add-product')}
      </div>
    )
  }
}

export default AddProductCapture;
