/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useLazyQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { AUTHENTICATE_ACCOUNT } from '../components/AuthService/AuthService';
import Button from 'react-bootstrap/Button';
import { useForm } from '../../shared/hooks/useForm';
import jwt from 'jsonwebtoken';

function refreshPage() {
  window.location.reload(false);
}

function AuthLogin() {

  // Use form state
  const { values, handleChange, handleSubmit } = useForm(() => loginUser());

  // Lazy query for login user method
  const [loginUser, { called, loading, data, error }] = useLazyQuery(AUTHENTICATE_ACCOUNT, { variables: values })


  // Wait for lazy query
  if (called && loading) return <p>lädt...</p>

  // Show error message if lazy query fails
  if (error) return <p>{error.message} </p>

  // Store token if login is successful
  if (data) {
    if (!data.authenticateAccount) {
      return (
        <Container>
          <Col md="6" className="mx-auto">
            <p>Falsche Anmeldedaten!</p>
            <Button onClick={refreshPage}>Zurück</Button>
          </Col>
        </Container>
      );
    }
    window.localStorage.setItem('token', data.authenticateAccount.authToken)

    const user = jwt.decode(data.authenticateAccount.authToken)

    if (user.accountType === 'ADMIN' || user.accountType === 'PRODUCER') {
      return (
        <div>
          {window.location.assign('/dashboard')}
        </div>
      )
    } else if (user.accountType === 'CONSUMER') {
      return (
        <div>
          {window.location.assign('/')}
        </div>
      )
    } else {
      // Redirect to home page
      return (
        <div>
          {refreshPage()}
        </div>
      )
    }

  }

  return (

    <Container>
      <h1>Login</h1>

      <Col md="6" className="mx-auto">
        <p>Bitte melden Sie sich mit ihrer E-Mail-Adresse an.</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmail">
            <Form.Control type="email" name="email" placeholder="E-Mail Adresse" onChange={handleChange} value={values.email} required />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Control type="password" name="password" placeholder="Passwort" onChange={handleChange} value={values.password} required />

            <Link to="/auth/reset-password" css={{ fontSize: '1rem' }}>Passwort vergessen?</Link>
          </Form.Group>
          <Row>
            <Col md={{ span: 4, offset: 0 }}>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </Col>
            <Col md={{ span: 6, offset: 0 }}>
              <Link className="btn btn-primary" to="/auth/register">Registrieren</Link>
            </Col>
          </Row>
        </Form>
      </Col>
    </Container>
  );
}

export default AuthLogin;
