/** @jsx jsx */
import { jsx } from "@emotion/core";
// import {Link} from 'react-router-dom';
//import { useEffect } from 'react';

//Bootstrap
import { InputGroup, FormControl } from "react-bootstrap";
import Button from 'react-bootstrap/Button'

// Components


// Theme
// import theme from '../../app/theme';




function ProductAmountCounter({ props }) {

  /*   // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      // Update the document title using the browser API
      document.title = `You clicked ${props.amount} times`;
    }); */

  function decreaseAmount(e) {
    props.setAmount(props.amount - 1);

    if (props.amount === 1) {
      props.setAmount(1)
    }
  }

  function manualAmount(event) {
    if (event.target.value !== "") {
      console.log(event.target.value)
      props.setAmount(parseFloat(event.target.value))
    } else {
      props.setAmount(1)
    }

  }

  return (
    <div className='AmountContainer'>

      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <Button variant="outline-secondary" onClick={decreaseAmount} size='sm' css={{
            minWidth: "25px",
            maxWidth: "100px",
            boxRadius: '5px 0px 0px 5px',
            color: 'black',
          }}>-</Button>
        </InputGroup.Prepend>

        {/* TODO: Größe anpassen */}
        <FormControl size='md'
          value={props.amount}
          onChange={manualAmount}
          css={{
            minWidth: "50px",
            maxWidth: "35px",
          }}
        />

        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={() => props.setAmount(props.amount + 1)} size='sm' css={{
            minWidth: "25px",
            maxWidth: "100px",
            // borderRadius: '0px 5px 5px 0px',
            color: 'black',
          }}>+</Button>
        </InputGroup.Append>
      </InputGroup>

    </div>
  );
}

export default ProductAmountCounter;
